import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import { getRouterHistory } from "../../../_store";
import TenantEditComponent from "./tenant-edit.component";
import { getTenantStatus } from "../../../_const/enums";
import {
  getTenant,
  updateTenant,
  createTenant,
  deleteTenant,
  getTenants,
  importTenant,
} from "../../../_services/tenantService";
import { sendEmailToAllTenants } from "../../../_services/buildingService";
import BroadcastEmailComponent from "./broadcast-email.component";
import { HiddenInputFileStyled } from "../../../_components/controls/button.component";
import withLoading from "../../../_components/controls/loading.component";

const TenantsContainer = withLoading((props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingTenant, setEditingTenant] = useState<any>(null);
  const [broadcastEmail, setBroadcastEmail] = useState<any>(null);
  const [searchData, setSearchData] = useState({ name: "" });
  const [importElement, setImportElement] = useState<any>(null);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getTenants,
      searchData.name,
      props.rest.buildingId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.tenants);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, props.rest.buildingId, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getTenant, record.id).then((response: any) => {
      setEditingTenant({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteTenant, record.id);
      message.info(`Deleted tenant ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingTenant({});
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingTenant.id) {
        await applyApiService(dispatch, updateTenant, editingTenant.id, values);
        message.info(`Updated tenant ${values.name}`);
        const index = data.findIndex((i: any) => i.id === editingTenant.id);
        data[index] = values;
        setEditingTenant(undefined);
      } else {
        values.buildingId = props.rest.buildingId;
        var newTenant = await applyApiService(dispatch, createTenant, values);
        message.info(`Created tenant ${values.name}`);
        setData([newTenant, ...data]);
        setEditingTenant(newTenant);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const openFileClick = (event: any) => {
    if (importElement) {
      importElement.input.click();
    }
  };

  const importClick = async (event: any) => {
    await applyApiService(
      dispatch,
      importTenant,
      props.rest.clientId,
      props.rest.buildingId,
      event.target.files
    );
    // importElement.input.value = "";
    message.info(`Imported tenants`);
    applyApiService(
      dispatch,
      getTenants,
      searchData.name,
      props.rest.buildingId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.tenants);
      setCount(response.count);
    });
  };
  const handleCancel = () => {
    setEditingTenant(undefined);
  };

  const handleCancelSendEmail = () => {
    setBroadcastEmail(undefined);
  };
  const broadcastEmailsClick = () => {
    setBroadcastEmail({});
  };

  const sendEmailToAll = async (values: any) => {
    await sendEmailToAllTenants(
      props.rest.buildingId,
      values.subject,
      values.content
    );
    setBroadcastEmail(undefined);
  };
  const checkTenant = () => {
    if (data.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const searchTenants = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ name: "" });
    } else {
      setSearchData({ name: e.target.value });
    }
  };

  const columns = [
    {
      title: "Resident Name",
      dataIndex: "name",
    },
    {
      title: "Unit Number",
      dataIndex: "unitNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        return <>{record.status ? getTenantStatus(record.status)?.name : ""}</>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Residents"
            handleSearch={searchTenants}
            canAdd={true}
            addText="Add Resident"
            addClick={addClick}
            additionalComponents={
              <>
                <button
                  onClick={broadcastEmailsClick}
                  className="btn-background-navy border shadow rounded h-auto float-left md:float-right m-0 mr-1"
                  disabled={checkTenant()}
                  type="button"
                >
                  <span>Email Residents</span>
                </button>
                <button
                  onClick={openFileClick}
                  className="btn-background-navy border shadow rounded h-auto float-left md:float-right m-0 mr-1"
                  type="button"
                >
                  <span>Click to Import</span>
                </button>
                <HiddenInputFileStyled
                  type="file"
                  onChange={importClick}
                  ref={(input) => {
                    setImportElement(input);
                  }}
                />
              </>
            }
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingTenant && (
        <TenantEditComponent
          handleSubmit={handleConfirmEdit}
          editingTenant={editingTenant}
          handleCancel={handleCancel}
        />
      )}

      {broadcastEmail && (
        <BroadcastEmailComponent
          handleSubmit={sendEmailToAll}
          broadcastEmail={broadcastEmail}
          handleCancel={handleCancelSendEmail}
        />
      )}
    </>
  );
});

export default TenantsContainer;
