import { handleActions } from "redux-actions";
import Cookies from "universal-cookie";
import * as types from "./types";

const cookies = new Cookies();
const currentUser = cookies.get("currentUser");

const defaultState = {
  currentUser: currentUser ?? {},
};

export default handleActions(
  {
    [types.LOGIN_SUCCESS]: (state, { payload }) => ({
      currentUser: payload,
    }),
    [types.LOGOUT]: (state) => ({ currentUser: {} }),
  },
  defaultState
);
