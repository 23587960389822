import { AutoComplete, Input, message, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../_components/action/action.component";
import LayoutComponent from "../../_components/layout.component";
import SearchComponent from "../../_components/search/search.component";
import TableComponent from "../../_components/table/table.component";
import { tableConfig } from "../../_const";
import { applyApiService } from "../../_services/apiService";
import {
  createBuilding,
  deleteBuilding,
  getBuildingById,
  getBuildings,
  updateBuilding,
} from "../../_services/buildingService";
import { updateUser, getUser } from "../../_services/userService";
import { getClient } from "../../_services/clientService";
import { uploadFile } from "../../_services/fileService";
import { getRouterHistory } from "../../_store";
import BuildingEditComponent from "./building-edit.component";
import { State } from "../../_const/enums";
import { searchClients } from "../../_services/workService";
import { debounce } from "lodash";

const BuildingContainer = (props: any) => {
  const history = getRouterHistory();
  const params = new URLSearchParams(document.location.search.substring(1));
  const edit = params.get("edit");
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [clientData, setClientData] = useState<any>([]);
  const [editingBuilding, setEditingBuilding] = useState<any>(null);
  const [searchData, setSearchData] = useState({ name: "" });
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(props.match.params.clientId);
  const [clientName, setClientName] = useState("");
  const [buildingId, setBuildingId] = useState(props.match.params.buildingId);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    if (clientId) {
      applyApiService(dispatch, getClient, clientId).then((response: any) => {
        setClientData(response);
        setClientId(response.id);
        setClientName(
          (response.companyName ? response.companyName + " | " : "") +
            response.name
        );
      });
    }
  }, [clientId, dispatch]);

  const editClick = (event: any, record: any) => {
    if (event) {
      event.preventDefault();
    }
    setEditMode(true);
    applyApiService(dispatch, getBuildingById, record.id).then(
      (response: any) => {
        setEditingBuilding({ ...response });
      }
    );
  };

  useEffect(() => {
    if (buildingId) {
      if (edit) {
        editClick(null, { id: buildingId });
      } else {
        viewClick(null, { id: buildingId });
      }
    } else {
      applyApiService(
        dispatch,
        getBuildings,
        searchData.name,
        offsetParam,
        limitParam,
        clientId
      ).then((response: any) => {
        setData(response.buildings);
        setCount(response.count);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, limitParam, offsetParam, searchData, clientId, buildingId]);

  const viewClick = (event: any, record: any) => {
    if (event) {
      event.preventDefault();
    }
    setEditMode(false);
    applyApiService(dispatch, getBuildingById, record.id).then(
      (response: any) => {
        setEditingBuilding({ ...response });
      }
    );
  };

  const handleSearchClients = (keyword: string) => {
    applyApiService(dispatch, searchClients, keyword).then(
      ({ clients }: { clients: [] }) => {
        setClients(clients || []);
      }
    );
  };

  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteBuilding, record.id);
      message.info(`Deleted building ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    if (clientId && clientData) {
      setEditingBuilding({
        clientId: clientId,
        clientName: `${clientData.companyName} | ${clientData.name}`,
        hasParams: true,
        state: State,
      });
      setEditMode(false);
    } else {
      setEditingBuilding({ state: State, clientId: null, hasParams: false });
      setEditMode(false);
    }
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (values.newDocuments) {
        await applyApiService(dispatch, uploadFile, values.newDocuments).then(
          (items: any) => {
            if (!values.documents) {
              values.documents = [];
            }
            items.forEach((item: any) => {
              values.documents.push(item);
            });
          }
        );
      }
      if (editingBuilding.id) {
        let buildingManager: any;
        let response = await applyApiService(
          dispatch,
          updateBuilding,
          editingBuilding.id,
          values
        );
        setEditingBuilding({ ...response.data });

        await applyApiService(dispatch, getUser, values.buildingManagerId).then(
          (response: any) => {
            if (values.buildingManagerPhone)
              response.phone = values.buildingManagerPhone;
            if (values.buildingManagerEmail)
              response.email = values.buildingManagerEmail;
            buildingManager = response;
          }
        );
        await applyApiService(
          dispatch,
          updateUser,
          values.buildingManagerId,
          buildingManager
        );
        message.info(`Updated building ${values.name}`);
        const index = data.findIndex((i: any) => i.id === editingBuilding.id);
        data[index] = values;
        values.newDocuments = [];
      } else {
        var newBuilding = await applyApiService(
          dispatch,
          createBuilding,
          values
        );
        message.info(`Created building ${values.name}`);
        setData([newBuilding, ...data]);
        setEditingBuilding(newBuilding);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingBuilding(undefined);
    if (clientId) {
      history.push(`/clients/${clientId}/buildings`);
    } else {
      history.push(`/buildings`);
      setBuildingId(undefined);
      setClientId(undefined);
    }
  };

  const searchBuildings = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ name: "" });
    } else {
      setSearchData({ name: e.target.value });
    }
  };

  const toClientOptions = (clients: any) =>
    clients.map((client: any) => (
      <Select.Option key={client.id} value={client.id}>
        {client.companyName ? client.companyName + " | " : ""}
        {client.name}
      </Select.Option>
    ));

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
    },
    {
      title: "Building Name",
      dataIndex: "name",
      render: (text: string, record: any) => {
        return (
          <button
            onClick={(e: any) => {
              history.push(
                `/clients/${record.clientId}/buildings/${record.id}`
              );
              viewClick(e, record);
            }}
          >
            {text}
          </button>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "streetName",
    },
    {
      title: "Strata Plan No.",
      dataIndex: "strataPlanNo",
    },
    {
      title: "Actions",
      key: "action",
      render: (record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={(e: any) => {
              history.push(
                `/clients/${record.clientId}/buildings/${record.id}?edit=true`
              );
              editClick(e, record);
            }}
          />
        );
      },
    },
  ];

  return (
    <LayoutComponent>
      <h1 className="mx-6 mt-4 text-lg">Buildings</h1>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Building"
            handleSearch={searchBuildings}
            canAdd={!clientId || (clientId && clientData)}
            addText="Add Building"
            addClick={addClick}
            additionalFilterComponents={
              <AutoComplete
                dropdownMatchSelectWidth={false}
                dataSource={toClientOptions(clients)}
                placeholder="Search to select client"
                onSelect={(value) => {
                  const selectedClient: any = clients.find(
                    (item: any) => item.id === value
                  );
                  setClientId(selectedClient.id);
                  setClientName(
                    (selectedClient.companyName
                      ? selectedClient.companyName + " | "
                      : "") + selectedClient.name
                  );
                }}
                onChange={(keyword) => {
                  setClientName(keyword as string);
                }}
                onSearch={debounce(handleSearchClients, 1000)}
                value={clientName}
              >
                <Input.Search size="large" />
              </AutoComplete>
            }
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingBuilding && (
        <BuildingEditComponent
          editMode={editMode}
          handleSubmit={handleConfirmEdit}
          editingBuilding={editingBuilding}
          handleCancel={handleCancel}
        />
      )}
    </LayoutComponent>
  );
};

export default BuildingContainer;
