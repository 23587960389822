import { Collapse } from "antd";
import React, { useState } from "react";
import { Form as FinalForm } from "react-final-form";
import ServiceEditForm, {
  validationSchema,
} from "./service-edit-form.component";
import { DrawerStyled } from "../../../_style/form";
import TasksContainer from "./tasks.container";
import { validate } from "../../../_utils/validation";

const ServicesEditComponent = ({
  editingServices,
  handleCancel,
  handleSubmit,
}: {
  editingServices: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([
    "service",
    "task-details",
  ]);
  return (
    <DrawerStyled
      placement="right"
      title={editingServices.id ? "Edit Service" : "Add Service"}
      visible={editingServices !== null}
      closable={true}
      onClose={handleCancel}
    >
      <Collapse
        activeKey={activeKeys}
        onChange={(keys: any) => {
          setActiveKeys([...keys]);
        }}
      >
        <Collapse.Panel
          header={editingServices.id ? editingServices.name : "Add Service"}
          key="service"
        >
          <FinalForm
            render={(props) => (
              <ServiceEditForm
                rest={{
                  handleCancel: handleCancel,
                }}
                {...props}
              />
            )}
            onSubmit={handleSubmit}
            validate={(values) => validate(values, validationSchema)}
            initialValues={editingServices}
          />
        </Collapse.Panel>
        {editingServices && editingServices.id && (
          <Collapse.Panel header="Task Details" key="task-details">
            <TasksContainer serviceId={editingServices.id} />
          </Collapse.Panel>
        )}
      </Collapse>
    </DrawerStyled>
  );
};

export default ServicesEditComponent;
