import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getAssets(
  keyword: string,
  buildingId: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/assets?keyword=${
        keyword ? keyword : ""
      }&buildingId=${buildingId}&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        assets: response.data.items,
        count: response.data.total,
      };
    });
}

export function getAsset(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/assets/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteAssets(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/assets/${id}`);
}

export function updateAssets(id: string, asset: object) {
  return baseAxios.request.put(`${baseUrl}v1/assets/${id}`, asset);
}

export function createAssets(asset: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/assets`, asset)
    .then((response: any) => response.data);
}
