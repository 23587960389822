import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getUsers(keyword: string, offset: number, limit: number) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/users?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        users: response.data.items,
        count: response.data.total,
      };
    });
}

export function getUser(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/users/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteUsers(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/users/${id}`);
}

export function updateUser(id: string, user: object) {
  return baseAxios.request.put(`${baseUrl}v1/users/${id}`, user);
}

export function createUser(user: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/users`, user)
    .then((response: any) => response.data);
}

export function searchBuildingManagers(
  keyword: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/buildingManagers?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        users: response.data.items,
        count: response.data.total,
      };
    });
}

export function searchStrataManagers(keyword: string, clientId: string) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/xeroContactPeople?keyword=${
        keyword ? keyword : ""
      }&clientId=${clientId ? clientId : ""}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        users: response.data.items,
        count: response.data.total,
      };
    });
}

export function getUserRole(email: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/roles/${encodeURIComponent(email)}`)
    .then((response: any) => {
      return response.data;
    });
}
