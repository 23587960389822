import moment from "moment";
import { Table } from "antd";
import React, { useCallback, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import LayoutComponent from "../../_components/layout.component";
import TableComponent from "../../_components/table/table.component";
import { tableConfig } from "../../_const";
import {
  // AuditHistoryReport,
  // DailyActivitiesReport,
  DefectsRepairsLoggedReport,
  // MaintenanceScheduledReport,
  MaintenanceWorkRequestsReport,
} from "../../_const/enums";
import {
  // getAuditHistoryReport,
  // getDailyActivityReport,
  getDefectsRepairsLoggedReport,
  // getMaintenanceScheduledReport,
  getMaintenanceWorkRequestsReport,
} from "../../_services/reportService";
import { getRouterHistory } from "../../_store";
// import { AuditHistoryColumns } from "./audit-history-columns";
// import { DailyActivityColumns } from "./daily-activity-columns";
import { DefectsRepairsLoggedColumns } from "./defects-repairs-logged-columns";
// import { MaintenanceScheduledColumns } from "./maintenance-scheduled-columns";
import { MaintenanceWorkRequestsColumns } from "./maintenance-work-requests-columns";
import SearchReportComponent from "./search.report.component";
import withLoading from "../../_components/controls/loading.component";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { applyApiService } from "../../_services/apiService";
import { useDispatch } from "react-redux";

const ReportContainer = withLoading((props: any) => {
  const dispatch = useDispatch();

  const history = getRouterHistory();
  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [fromDate, setFromDate] = useState(
    moment().subtract(30, "days").startOf("day")
  );
  const [toDate, setToDate] = useState(moment().endOf("day"));
  const [reportType, setReportType] = useState(MaintenanceWorkRequestsReport);
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );

  const columnDictionary = [
    // { key: AuditHistoryReport, value: AuditHistoryColumns },
    // { key: MaintenanceScheduledReport, value: MaintenanceScheduledColumns },
    {
      key: MaintenanceWorkRequestsReport,
      value: MaintenanceWorkRequestsColumns,
    },
    { key: DefectsRepairsLoggedReport, value: DefectsRepairsLoggedColumns },
    // { key: DailyActivitiesReport, value: DailyActivityColumns },
  ];

  const getDataDictionary = [
    // { key: AuditHistoryReport, value: getAuditHistoryReport },
    // { key: MaintenanceScheduledReport, value: getMaintenanceScheduledReport },
    {
      key: MaintenanceWorkRequestsReport,
      value: getMaintenanceWorkRequestsReport,
    },
    { key: DefectsRepairsLoggedReport, value: getDefectsRepairsLoggedReport },
    // { key: DailyActivitiesReport, value: getDailyActivityReport },
  ];

  const handleSubmit = useCallback(
    (values: any) => {
      setReportType(values.report);
      setFromDate(values.fromDate);
      setToDate(values.toDate);

      setData([]);
      setColumns(columnDictionary.find((i) => i.key === values.report)?.value);
      var method = getDataDictionary.find((i) => i.key === values.report);

      applyApiService(
        dispatch,
        method?.value,
        "",
        values.fromDate.toDate(),
        values.toDate.toDate(),
        offsetParam,
        limitParam
      ).then(({ items, count }: { items: any[]; count: number }) => {
        setCount(count);
        setData(items);
      });

      // method
      //   ?.value(
      //     "",
      //     values.fromDate.toDate(),
      //     values.toDate.toDate(),
      //     offsetParam,
      //     limitParam
      //   )
      //   .then(({ items, count }: { items: any[]; count: number }) => {
      //     setCount(count);
      //     setData(items);
      //   });
    },
    [columnDictionary, dispatch, getDataDictionary, limitParam, offsetParam]
  );

  const handleExport = () => {
    if (reportType === 4) {
      history.push("/export/defects", data);
    } else {
      const filePDF = new jsPDF();
      autoTable(filePDF, { html: "#reportTable table" });
      const fileName = "Audit-Report-" + moment().format("DD/MM/YYYY") + ".pdf";
      filePDF.save(fileName);
    }
  };

  return (
    <LayoutComponent>
      <h1 className="mx-6 mt-4 text-lg">Report</h1>
      <FinalForm
        render={(props) => (
          <SearchReportComponent handleExport={handleExport} {...props} />
        )}
        onSubmit={handleSubmit}
        initialValues={{
          report: reportType,
          fromDate: fromDate,
          toDate: toDate,
        }}
      />
      {reportType === 5 && (
        <>
          <h1 className="ml-6 text-red-700">
            * This feature is not available now, we are working on it and will
            update soon
          </h1>
        </>
      )}
      <div id="reportTable" className="hidden">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      </div>

      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>
    </LayoutComponent>
  );
});

export default ReportContainer;
