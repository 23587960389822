import {
  Alert,
  AutoComplete,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../_components/controls/button.component";
import { ErrorRowStyled } from "../../_components/controls/grid.component";
import withLoading from "../../_components/controls/loading.component";
import { CommercialClientType, ResidentalClientType } from "../../_const/enums";
import { applyApiService } from "../../_services/apiService";
import { searchContacts } from "../../_services/clientService";
import { formItemLayout } from "../../_style/form";
import { validate, validateStatus } from "../../_utils/validation";

const validationSchema = Yup.object({
  name: Yup.string().required("Client name is required"),
  email: Yup.string().email(),
});

const ClientEditForm = withLoading(
  ({
    handleSubmit,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: {
      change,
      mutators: { push },
    },
    values,
    rest,
  }: {
    handleSubmit: any;
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
    rest: { handleCancel: any };
  }) => {
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState<string>("");
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
      setKeyword(values.companyName);
    }, [values.companyName]);

    const toContactOptions = (contacts: any) =>
      contacts.map((contact: any) => (
        <Select.Option key={contact.contactId} value={contact.contactId}>
          {contact.name}
        </Select.Option>
      ));

    const handleSearchContacts = (keyword: string) => {
      applyApiService(dispatch, searchContacts, keyword).then(
        ({ contacts }: { contacts: [] }) => {
          setContacts(contacts || []);
        }
      );
    };

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="Company Name"
          validateStatus={validateStatus(
            touched.companyName,
            errors.companyName
          )}
          help={touched.companyName && errors.companyName}
        >
          <AutoComplete
            notFoundContent={<Spin />}
            dropdownMatchSelectWidth={false}
            dataSource={toContactOptions(contacts)}
            placeholder="Search to select a Xero contact"
            onSelect={(value, option) => {
              const selectedContact: any = contacts.find(
                (item: any) => item.contactId === value
              );
              change("companyName", selectedContact.name);
              change("xeroClientId", selectedContact.contactId);
              change("address", selectedContact.address);
              change("phone", selectedContact.phone);
              change("email", selectedContact.emailAddress);
              change("strataPlanNumber", selectedContact.strataPlanNumber);
              change(
                "name",
                selectedContact.firstName
                  ? selectedContact.firstName +
                      (selectedContact.lastName
                        ? " " + selectedContact.lastName
                        : "")
                  : ""
              );
              setKeyword(selectedContact.name);
            }}
            onChange={(keyword) => {
              setKeyword(keyword as string);
            }}
            onSearch={debounce(handleSearchContacts, 1000)}
            value={keyword}
          >
            <Input.Search size="large" />
          </AutoComplete>
        </Form.Item>{" "}
        <Form.Item label="Client Type">
          <Field name="clientType">
            {({ input }) => (
              <Radio.Group {...input}>
                <Radio value={CommercialClientType}>Commercial</Radio>
                <Radio value={ResidentalClientType}>Residental</Radio>
              </Radio.Group>
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Contact Name"
          validateStatus={validateStatus(touched.name, errors.name)}
          help={touched.name && errors.name}
        >
          <Field name="name">
            {({ input }) => (
              <Input type="text" placeholder="Contact Name" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Contact Phone"
          validateStatus={validateStatus(touched.phone, errors.phone)}
          help={touched.phone && errors.phone}
        >
          <Field name="phone">
            {({ input }) => (
              <Input type="text" placeholder="Contact Phone" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Contact Email"
          validateStatus={validateStatus(touched.email, errors.email)}
          help={touched.email && errors.email}
        >
          <Field name="email">
            {({ input }) => (
              <Input type="text" placeholder="Contact Email" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Contact Address"
          validateStatus={validateStatus(touched.address, errors.address)}
          help={touched.address && errors.address}
        >
          <Field name="address">
            {({ input }) => (
              <Input type="text" placeholder="Contact Address" {...input} />
            )}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const ClientEditComponent = ({
  editingClient,
  handleCancel,
  handleSubmit,
}: {
  editingClient: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  return (
    <Modal
      title={editingClient?.id ? "Edit Client" : "Add Client"}
      visible={editingClient !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <FinalForm
        render={(props) => (
          <ClientEditForm
            rest={{
              handleCancel: handleCancel,
            }}
            {...props}
          />
        )}
        validate={(values) => validate(values, validationSchema)}
        onSubmit={handleSubmit}
        initialValues={editingClient}
      />
    </Modal>
  );
};

export default ClientEditComponent;
