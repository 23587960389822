import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
import axios from "axios";
import moment from "moment";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getAuditHistoryReport(
  userId: string,
  fromDate: Date,
  toDate: Date,
  offset?: number,
  limit?: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/report/audit-history?userId=${
        userId ? userId : ""
      }&fromDate=${
        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
      }&toDate=${toDate ? moment(toDate).format("YYYY-MM-DD") : ""}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        items: response.data.items,
        count: response.data.total,
      };
    });
}

export function getMaintenanceWorkRequestsReport(
  userId: string,
  fromDate: Date,
  toDate: Date,
  offset?: number,
  limit?: number
) {
  return baseAxios.request
    .get(
      `${baseUrl}v1/report/maintenance-work-requests?userId=${
        userId ? userId : ""
      }&fromDate=${
        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
      }&toDate=${toDate ? moment(toDate).format("YYYY-MM-DD") : ""}`
    )
    .then((response: any) => {
      return {
        items: response.data.items,
        count: response.data.total,
      };
    });
}

export function getDefectsRepairsLoggedReport(
  userId: string,
  fromDate: Date,
  toDate: Date,
  offset?: number,
  limit?: number
) {
  return baseAxios.request
    .get(
      `${baseUrl}v1/report/defects-repairs-logged?userId=${
        userId ? userId : ""
      }&fromDate=${
        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
      }&toDate=${toDate ? moment(toDate).format("YYYY-MM-DD") : ""}`
    )
    .then((response: any) => {
      return {
        items: response.data.items,
        count: response.data.total,
      };
    });
}

export function getDailyActivityReport(
  userId: string,
  fromDate: Date,
  toDate: Date,
  offset?: number,
  limit?: number
) {
  return baseAxios.request
    .get(
      `${baseUrl}v1/report/daily-activity?userId=${
        userId ? userId : ""
      }&fromDate=${
        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
      }&toDate=${toDate ? moment(toDate).format("YYYY-MM-DD") : ""}`
    )
    .then((response: any) => {
      return {
        items: response.data.items,
        count: response.data.total,
      };
    });
}
