import { Collapse } from "antd";
import React, { useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { DrawerStyled } from "../../../_style/form";
import TenantEditForm, { validationSchema } from "./tenant-edit-form.component";
import { validate } from "../../../_utils/validation";
import VehicleContainer from "../vehicle/vehicle.container";

const TenantEditComponent = ({
  editingTenant,
  handleCancel,
  handleSubmit,
}: {
  editingTenant: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([
    "tenant",
    "vehicle-details",
  ]);
  return (
    <DrawerStyled
      placement="right"
      title={editingTenant.id ? "Edit Resident" : "Add Resident"}
      visible={editingTenant !== null}
      closable={true}
      onClose={handleCancel}
    >
      <Collapse
        activeKey={activeKeys}
        onChange={(keys: any) => {
          setActiveKeys([...keys]);
        }}
      >
        <Collapse.Panel
          header={editingTenant.id ? editingTenant.name : "Add Resident"}
          key="tenant"
        >
          <FinalForm
            render={(props) => (
              <TenantEditForm
                rest={{
                  handleCancel: handleCancel,
                }}
                {...props}
              />
            )}
            onSubmit={handleSubmit}
            validate={(values) => validate(values, validationSchema)}
            initialValues={editingTenant}
          />
        </Collapse.Panel>
        {editingTenant && editingTenant.id && (
          <Collapse.Panel header="Vehicle Details" key="vehicle-details">
            <VehicleContainer tenantId={editingTenant.id} />
          </Collapse.Panel>
        )}
      </Collapse>
    </DrawerStyled>
  );
};

export default TenantEditComponent;
