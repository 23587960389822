import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import { getRouterHistory } from "../../../_store";
import {
  updateServices,
  deleteServices,
  getService,
  createServices,
  getServices,
} from "../../../_services/serviceService";
import ServicesEditComponent from "./service-edit.component";

const ServicesContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingServices, setEditingServices] = useState<any>(null);
  const [searchData, setSearchData] = useState({ name: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getServices,
      searchData.name,
      props.buildingId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.services);
      setCount(response.count);
    });
  }, [
    dispatch,
    limitParam,
    offsetParam,
    props.buildingId,
    searchData,
    editingServices,
  ]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getService, record.id).then((response: any) => {
      setEditingServices({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteServices, record.id);
      message.info(`Deleted service ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingServices({});
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingServices.id) {
        await applyApiService(
          dispatch,
          updateServices,
          editingServices.id,
          values
        );
        message.info(`Updated service ${values.name}`);
        const index = data.findIndex((i: any) => i.id === editingServices.id);
        data[index] = values;
        setEditingServices(undefined);
      } else {
        values.buildingId = props.buildingId;
        var newServices = await applyApiService(
          dispatch,
          createServices,
          values
        );
        message.info(`Created service ${values.name}`);
        setData([newServices, ...data]);
        setEditingServices(newServices);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingServices(undefined);
  };

  const searchService = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ name: "" });
    } else {
      setSearchData({ name: e.target.value });
    }
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "name",
    },
    {
      title: "Task",
      dataIndex: "tasks",
      render: (tasks: any[]) => {
        return (
          tasks &&
          tasks.length > 0 &&
          tasks.map((task: any) => <span>{task.name}; &nbsp;</span>)
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Service"
            handleSearch={searchService}
            canAdd={true}
            addText="Add Service"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingServices && (
        <ServicesEditComponent
          handleSubmit={handleConfirmEdit}
          editingServices={editingServices}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ServicesContainer;
