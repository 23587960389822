import { Collapse, Tabs } from "antd";
import React, { useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { validate } from "../../_utils/validation";
import { DrawerStyled } from "../../_style/form";
import BuildingEditForm, {
  validationSchema,
} from "./building-edit-form.component";
import ServicesContainer from "./service/service.container";
import TenantsContainer from "./tenant/tenant.container";
import DefectContainer from "./defect/defect.container";
import AssetsContainer from "./asset/assets.container";
import ScheduleContainer from "../schedule/schedule.container";
import WorkOrderContainer from "../work-order/work-order.container";

const { TabPane } = Tabs;

const BuildingEditComponent = ({
  editingBuilding,
  handleCancel,
  handleSubmit,
  editMode,
}: {
  editingBuilding: any;
  handleCancel: any;
  handleSubmit: any;
  editMode: boolean;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([
    editingBuilding.id && editMode === false ? "" : "building",
    "building-details",
  ]);
  return (
    <>
      <DrawerStyled
        placement="right"
        title={editingBuilding.id ? "Edit Building" : "Add Building"}
        visible={editingBuilding !== null}
        closable={true}
        onClose={handleCancel}
      >
        <Collapse
          activeKey={activeKeys}
          onChange={(keys: any) => {
            setActiveKeys([...keys]);
          }}
        >
          <Collapse.Panel
            header={editingBuilding.id ? editingBuilding.name : "Add Building"}
            key="building"
          >
            <FinalForm
              render={(props) => (
                <BuildingEditForm
                  rest={{
                    handleCancel: handleCancel,
                  }}
                  {...props}
                />
              )}
              validate={(values) => validate(values, validationSchema)}
              onSubmit={handleSubmit}
              initialValues={editingBuilding}
            />
          </Collapse.Panel>
          {editingBuilding && editingBuilding.id && (
            <Collapse.Panel header="Building Details" key="building-details">
              <Tabs defaultActiveKey="tenant">
                <TabPane tab="Resident" key="tenant">
                  <TenantsContainer
                    rest={{
                      buildingId: editingBuilding.id,
                      clientId: editingBuilding.clientId,
                    }}
                  />
                </TabPane>
                <TabPane tab="Defects and Repairs" key="defects">
                  <DefectContainer buildingId={editingBuilding.id} />
                </TabPane>
                <TabPane tab="Asset" key="assets">
                  <AssetsContainer buildingId={editingBuilding.id} />
                </TabPane>
                <TabPane tab="Services" key="services">
                  <ServicesContainer buildingId={editingBuilding.id} />
                </TabPane>
                <TabPane tab="Maintenance Schedule" key="maintenance">
                  <ScheduleContainer
                    rest={{
                      buildingId: editingBuilding.id,
                    }}
                  />
                </TabPane>
                <TabPane tab="Work Requests" key="workrequest">
                  <WorkOrderContainer
                    rest={{
                      buildingId: editingBuilding.id,
                      strataManagerName: editingBuilding.strataManagerName,
                    }}
                  />
                </TabPane>
              </Tabs>
            </Collapse.Panel>
          )}
        </Collapse>
      </DrawerStyled>
    </>
  );
};

export default BuildingEditComponent;
