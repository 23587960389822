import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getWorkOrders(
  keyword: string,
  buildingId: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/workOrders?keyword=${
        keyword ? keyword : ""
      }&buildingId=${buildingId}&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        workOrders: response.data.items,
        count: response.data.total,
      };
    });
}

export function getworkOrder(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/workOrders/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteworkOrders(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/workOrders/${id}`);
}

export function updateworkOrders(id: string, workOrder: any) {
  let formData = new FormData();
  if (workOrder.emailAttachments) {
    workOrder.emailAttachments.forEach((file: any) => {
      formData.append(file.originFileObj.name, file.originFileObj);
    });
  }
  formData.append("model", JSON.stringify(workOrder));
  return baseAxios
    .request({
      method: "put",
      url: `${baseUrl}v1/workOrders/${id}`,
      data: formData,
      headers: {
        "content-type": `multipart/form-data;`,
      },
    })
    .then((response: any) => {
      return response.data;
    });
}

export function createWorkOrder(workOrder: any) {
  let formData = new FormData();
  if (workOrder.emailAttachments) {
    workOrder.emailAttachments.forEach((file: any) => {
      formData.append(file.originFileObj.name, file.originFileObj);
    });
  }

  formData.append("model", JSON.stringify(workOrder));
  return baseAxios
    .request({
      method: "post",
      url: `${baseUrl}v1/workOrders`,
      data: formData,
      headers: {
        "content-type": `multipart/form-data;`,
      },
    })
    .then((response: any) => {
      return response.data;
    });
}

export function searchClients(keyword: string) {
  return baseAxios.request
    .get(`${apiUrl}v1/clients?keyword=${keyword}`)
    .then((response: any) => ({ clients: response.data.items }));
}

export function getClientById(id: string) {
  return baseAxios.request.get(`${baseUrl}v1/clients/${id}`).then(
    (response: any) => {
      return response.data;
    },
    (error: any) => {
      alert(error);
    }
  );
}
