import React, { useEffect, useState } from "react";
import LayoutComponent from "../../_components/layout.component";
import { useDispatch } from "react-redux";
import { applyApiService } from "../../_services/apiService";
import { oauthCallBack } from "../../_services/clientService";

const XeroContainer = () => {
  const dispatch = useDispatch();
  const [xeroMessage, setXeroMessage] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const code = params.get("code");
    applyApiService(dispatch, oauthCallBack, code).then(() => {
      setXeroMessage(
        "You allow Xero permission successfully. Now you can close this window"
      );
    });
  }, [dispatch]);

  return (
    <LayoutComponent>
      <h1 className="mx-6 mt-4 text-lg">Xero</h1>
      {xeroMessage && <p className="mx-6 mt-0">{xeroMessage}</p>}
    </LayoutComponent>
  );
};

export default XeroContainer;
