import { Col, DatePicker, Form, Row, Select } from "antd";
import moment from "moment";
import React from "react";
import { Field } from "react-final-form";
import { ReportType } from "../../_const/enums";

const SearchReportComponent = ({
  handleSubmit,
  handleExport,
}: {
  handleSubmit: any;
  handleExport: any;
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={24} sm={6}>
          <Form.Item label="Select Report">
            <Field name="report">
              {({ input }) => (
                <Select {...input}>
                  {ReportType.map((item: any) => (
                    <Select.Option value={item.key} key={item.key}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Field>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} sm={6}>
          <Form.Item label="From Date">
            <Field name="fromDate">
              {({ input }) => {
                return (
                  <DatePicker
                    style={{ width: "100%" }}
                    {...input}
                    value={
                      input.value
                        ? moment(input.value)
                        : moment().subtract(30, "days").startOf("day")
                    }
                    format="DD/MM/YYYY"
                  />
                );
              }}
            </Field>
          </Form.Item>
        </Col>
        <Col span={12} sm={6}>
          <Form.Item label="To Date">
            <Field name="toDate">
              {({ input }) => (
                <DatePicker
                  style={{ width: "100%" }}
                  {...input}
                  value={
                    input.value ? moment(input.value) : moment().endOf("day")
                  }
                  format="DD/MM/YYYY"
                />
              )}
            </Field>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} lg={12}>
          <button className="btn-background-navy border shadow rounded h-auto mb-4">
            <span>View Report</span>
          </button>
          <button
            id="print"
            onClick={handleExport}
            className="btn-background-navy border shadow rounded h-auto mb-4 ml-0 md:ml-2"
          >
            <span>Export</span>
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchReportComponent;
