import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import SearchComponent from "../../_components/search/search.component";
import TableComponent from "../../_components/table/table.component";
import { getRouterHistory } from "../../_store";
import { tableConfig } from "../../_const";
import { useDispatch } from "react-redux";
import { applyApiService } from "../../_services/apiService";
import {
  getWorkOrders,
  deleteworkOrders,
  updateworkOrders,
  createWorkOrder,
  getworkOrder,
} from "../../_services/workService";
import ActionComponent from "../../_components/action/action.component";
import { message } from "antd";
import { getWorkOrderStatus, RequestStatus } from "../../_const/enums";
import WorkOrderEditComponent from "./work-order-edit.component";
import withLoading from "../../_components/controls/loading.component";

const WorkOrderContainer = withLoading((props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingWorkOrder, setEditingWorkOrder] = useState<any>(null);
  const [searchData, setSearchData] = useState({ requestDetails: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getWorkOrders,
      searchData.requestDetails,
      props.rest.buildingId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.workOrders);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, searchData, props.rest.buildingId]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getworkOrder, record.id).then((response: any) => {
      setEditingWorkOrder({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteworkOrders, record.id);
      message.info(`Deleted work request ${record.requestDetails}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingWorkOrder({ status: RequestStatus });
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingWorkOrder.id) {
        await applyApiService(
          dispatch,
          updateworkOrders,
          editingWorkOrder.id,
          values
        );
        message.info(`Updated work request ${values.requestDetails}`);
        const index = data.findIndex((i: any) => i.id === editingWorkOrder.id);
        data[index] = values;
        setEditingWorkOrder(undefined);
      } else {
        var newWorkOrder = await applyApiService(
          dispatch,
          createWorkOrder,
          values
        );
        message.info(`Created work request ${values.requestDetails}`);
        setData([newWorkOrder, ...data]);
        setEditingWorkOrder(undefined);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingWorkOrder(undefined);
  };

  const searchWorkOrder = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ requestDetails: "" });
    } else {
      setSearchData({ requestDetails: e.target.value });
    }
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "companyName",
    },
    {
      title: "Strata Plan No",
      dataIndex: "strataPlanNo",
    },
    {
      title: "Details",
      dataIndex: "requestDetails",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        return getWorkOrderStatus(record.status).name;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Work request"
            handleSearch={searchWorkOrder}
            canAdd={true}
            addText="Add Work request"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>
      {editingWorkOrder && (
        <WorkOrderEditComponent
          handleSubmit={handleConfirmEdit}
          editingWorkOrder={editingWorkOrder}
          handleCancel={handleCancel}
          isLoading={props.isLoading}
          buildingId={props.rest.buildingId}
        />
      )}
    </>
  );
});

export default WorkOrderContainer;
