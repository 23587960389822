import React from "react";
import { Redirect, Route } from "react-router-dom";
import baseAxios from "./_services/baseAxios";
import { useSelector } from "react-redux";
import { selectors as authSelectors } from "../src/_store/app/auth";

const PrivateRoute = ({
  component,
  path,
  exact,
  name,
  roles,
  ...rest
}: {
  component: any;
  name: string;
  exact: boolean;
  path: string;
  roles?: any[];
}) => {
  const role = useSelector(authSelectors.currentRole);
  if (baseAxios.isLogedIn() && (!roles || roles.indexOf(role) >= 0)) {
    return <Route {...rest} component={component} />;
  } else {
    return <Redirect to="/login" />;
  }
};
export default PrivateRoute;
