import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
import moment from "moment";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getServiceHistory(
  keyword: string,
  id: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/assets/${id}/history?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        history: response.data.items,
        count: response.data.total,
      };
    });
}

export function getServiceHistories(assetId: string, id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/assets/${assetId}/history/${id}`)
    .then((response: any) => {
      response.data.date = moment(response.data.date);
      return response.data;
    });
}

export function deleteServiceHistory(assetId: string, id: string) {
  return baseAxios.request.delete(
    `${baseUrl}v1/assets/${assetId}/history/${id}`
  );
}

export function updateServiceHistory(
  assetId: string,
  id: string,
  service: object
) {
  return baseAxios.request.put(
    `${baseUrl}v1/assets/${assetId}/history/${id}`,
    service
  );
}

export function createServiceHistory(assetId: string, service: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/assets/${assetId}/history`, service)
    .then((response: any) => response.data);
}
