import {
  Alert,
  AutoComplete,
  Button,
  Form,
  Icon,
  Input,
  List,
  Row,
  Select,
  Upload,
  Col,
  Spin,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch } from "react-redux";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../_components/controls/button.component";
import { ErrorRowStyled } from "../../_components/controls/grid.component";
import { applyApiService } from "../../_services/apiService";
import { searchClients } from "../../_services/workService";
import {
  searchBuildingManagers,
  searchStrataManagers,
} from "../../_services/userService";
import { formItemLayout } from "../../_style/form";
import { validateStatus } from "../../_utils/validation";
import * as Yup from "yup";
import ActionComponent from "../../_components/action/action.component";
import { downloadFile } from "../../_services/fileService";
import TextArea from "antd/lib/input/TextArea";
// import ApiCalendar from "../../_services/apiCalendar";
import { debounce } from "lodash";
import { AddressState } from "../../_const/enums";
import { useSelector } from "react-redux";
import { selectors as authSelectors } from "../../../src/_store/app/auth";
import withLoading from "../../_components/controls/loading.component";

export const validationSchema = Yup.object({
  clientId: Yup.string().nullable().required("Client is required"),
  name: Yup.string().required("Building name is required"),
  buildingManagerId: Yup.string().required("Building manager is required"),
  strataManagerEmail: Yup.string().email(),
  buildingManagerEmail: Yup.string().email(),
});

const BuildingEditForm = withLoading(
  ({
    handleSubmit,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: { change },
    values,
    rest,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    const dispatch = useDispatch();
    const [clientName, setClientName] = useState<string>("");
    const [clientId, setClientId] = useState<string>("");
    const [clients, setClients] = useState([]);
    const [buildingManagerName, setBuildingManagerName] = useState<string>("");
    const [buildingManagers, setBuildingManagers] = useState([]);
    const [strataManagerName, setStrataManagerName] = useState<string>("");
    const [strataManagerId, setStrataManagerId] = useState<string>("");
    const [strataManagers, setStrataManagers] = useState<any[]>([]);
    const [file, setFile] = useState<any>([]);
    const role = useSelector(authSelectors.currentRole);

    useEffect(() => {
      const params = new URLSearchParams(document.location.search.substring(1));
      const clientId = params.get("clientId");
      setClientId(values.clientId || clientId);
      setClientName(values.clientName || "");

      if (values.buildingManager) {
        setBuildingManagerName(values.buildingManager.name || "");
      }
      setStrataManagerName(values.strataManagerName || "");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toClientOptions = (clients: any) =>
      clients.map((client: any) => (
        <Select.Option key={client.id} value={client.id}>
          {client.companyName ? client.companyName + " | " : ""}
          {client.name}
        </Select.Option>
      ));
    const toBuildingManagerOptions = (buildingManagers: any) =>
      buildingManagers.map((buildingManager: any) => (
        <Select.Option key={buildingManager.id} value={buildingManager.id}>
          {buildingManager.name}
        </Select.Option>
      ));
    const toStrataManagerOptions = (strataManagers: any) =>
      strataManagers.map((strataManager: any) => (
        <Select.Option key={strataManager.id} value={strataManager.id}>
          {strataManager.strataManagerName}
        </Select.Option>
      ));

    const handleSearchClients = (keyword: string) => {
      applyApiService(dispatch, searchClients, keyword).then(
        ({ clients }: { clients: [] }) => {
          setClients(clients || []);
        }
      );
    };

    const handleSearchBuildingManagers = useCallback(
      (keyword: string) => {
        applyApiService(dispatch, searchBuildingManagers, keyword, 0, 0).then(
          ({ users }: { users: [] }) => {
            setBuildingManagers(users || []);
          }
        );
      },
      [dispatch]
    );

    const handleSearchStrataManagers = useCallback(
      (keyword: string) => {
        applyApiService(dispatch, searchStrataManagers, keyword, clientId).then(
          ({ users }: { users: any[] }) => {
            setStrataManagers(users || []);
            if (users && users.length > 0) {
              const selectedStrataManager = users[0] as any;
              if (!strataManagerId && selectedStrataManager) {
                change("strataManagerId", selectedStrataManager.id);
                change(
                  "strataManagerName",
                  selectedStrataManager.strataManagerName
                );
                change(
                  "strataManagerEmail",
                  selectedStrataManager.strataManagerEmail
                );
                change(
                  "strataManagerPhone",
                  selectedStrataManager.strataManagerPhone
                );
                setStrataManagerName(selectedStrataManager.strataManagerName);
                setStrataManagerId(selectedStrataManager.id);
              }
            }
          }
        );
      },
      [change, clientId, dispatch, strataManagerId]
    );

    useEffect(() => {
      if (clientId) {
        handleSearchBuildingManagers("");
        handleSearchStrataManagers("");
      }
    }, [clientId, handleSearchBuildingManagers, handleSearchStrataManagers]);

    const handleUpload = useCallback(
      (data: any) => {
        change("newDocuments", data.fileList);
        const newFile = [...data.fileList];
        let arr: any[] = [];
        newFile.map((fl: any) => {
          return arr.push(fl);
        });
        if (arr) setFile(arr);
      },
      [change]
    );
    const handleChangeFile = () => {
      if (file.length > 0) return false;
      else return true;
    };

    const deleteDocumentClick = useCallback(
      (document: any) => {
        const documents = values.documents.filter(
          (item: any) => item.id !== document.id
        );
        let arr: any[] = [];
        if (document) arr.push(document);
        setFile(arr);
        change("documents", documents);
      },
      [change, values.documents]
    );
    const downloadDocumentClick = useCallback(
      (document: any) => {
        applyApiService(dispatch, downloadFile, document.id);
      },
      [dispatch]
    );
    const handleSelectState = (value: any) => {
      change("state", value);
    };

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Client"
              required={true}
              validateStatus={validateStatus(touched.clientId, errors.clientId)}
              help={touched.clientId && errors.clientId}
            >
              <Field name="clientId">
                {({ input }) => (
                  <AutoComplete
                    disabled={values.hasParams}
                    dropdownMatchSelectWidth={false}
                    dataSource={toClientOptions(clients)}
                    placeholder="Search to select client"
                    onSelect={(value) => {
                      const selectedClient: any = clients.find(
                        (item: any) => item.id === value
                      );
                      change("clientId", selectedClient.id);
                      change("clientName", selectedClient.companyName);
                      change("streetName", selectedClient.address);

                      setClientName(
                        (selectedClient.companyName
                          ? selectedClient.companyName + " | "
                          : "") + selectedClient.name
                      );

                      setClientId(selectedClient.id);
                    }}
                    onChange={(keyword) => {
                      setClientName(keyword as string);
                      change("clientId", "");
                    }}
                    onSearch={debounce(handleSearchClients, 1000)}
                    value={clientName}
                  >
                    <Input.Search size="large" />
                  </AutoComplete>
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Building Name"
              required={true}
              validateStatus={validateStatus(touched.name, errors.name)}
              help={touched.name && errors.name}
            >
              <Field name="name">
                {({ input }) => (
                  <Input type="text" placeholder="Building Name" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Strata Plan Number"
              validateStatus={validateStatus(
                touched.strataPlanNo,
                errors.strataPlanNo
              )}
              help={touched.strataPlanNo && errors.strataPlanNo}
              className="whitespace-label"
            >
              <Field name="strataPlanNo">
                {({ input }) => (
                  <Input
                    type="text"
                    placeholder="Strata Plan Number"
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <h1 className="font-semibold text-xl">Address:</h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Unit/Floor"
              validateStatus={validateStatus(
                touched.unitFloor,
                errors.unitFloor
              )}
              help={touched.unitFloor && errors.unitFloor}
            >
              <Field name="unitFloor">
                {({ input }) => (
                  <Input type="text" placeholder="Unit/Floor" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Building Number"
              validateStatus={validateStatus(
                touched.buildingNumber,
                errors.buildingNumber
              )}
              help={touched.buildingNumber && errors.buildingNumber}
            >
              <Field name="buildingNumber">
                {({ input }) => (
                  <Input type="text" placeholder="Building Number" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Street Name"
              validateStatus={validateStatus(
                touched.streetName,
                errors.streetName
              )}
              help={touched.streetName && errors.streetName}
            >
              <Field name="streetName">
                {({ input }) => (
                  <Input type="text" placeholder="Street Name" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Suburb"
              validateStatus={validateStatus(touched.suburb, errors.suburb)}
              help={touched.suburb && errors.suburb}
            >
              <Field name="suburb">
                {({ input }) => (
                  <Input type="text" placeholder="Suburb" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="State"
              validateStatus={validateStatus(touched.state, errors.state)}
              help={touched.state && errors.state}
            >
              <Field name="state">
                {({ input }) => (
                  <Select {...input} onSelect={handleSelectState}>
                    {AddressState.map((item: any) => (
                      <Select.Option value={item.key} key={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Postcode"
              validateStatus={validateStatus(touched.postcode, errors.postcode)}
              help={touched.postcode && errors.postcode}
            >
              <Field name="postcode">
                {({ input }) => (
                  <Input type="text" placeholder="Postcode" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <h1 className="font-semibold text-xl">Contacts:</h1>
          </Col>
        </Row>
        <Row gutter={16} className="mt-4">
          <Col span={24} md={12}>
            <h6 className="font-semibold sm:w-full md:w-1/3 text-left sm:text-right">
              Building Manager:
            </h6>
            <Form.Item
              label="Name"
              validateStatus={validateStatus(
                touched.buildingManagerId,
                errors.buildingManagerId
              )}
              required={true}
              help={touched.buildingManagerId && errors.buildingManagerId}
            >
              <Field name="buildingManagerId">
                {({ input }) => (
                  <div {...input}>
                    <AutoComplete
                      notFoundContent={<Spin />}
                      dropdownMatchSelectWidth={false}
                      dataSource={toBuildingManagerOptions(buildingManagers)}
                      placeholder="Search to select contact"
                      onSelect={(value) => {
                        const selectedBuildingManager: any =
                          buildingManagers.find(
                            (item: any) => item.id === value
                          );
                        change("buildingManagerId", selectedBuildingManager.id);
                        change(
                          "buildingManagerPhone",
                          selectedBuildingManager.phone
                        );
                        change(
                          "buildingManagerEmail",
                          selectedBuildingManager.email
                        );
                        setBuildingManagerName(selectedBuildingManager.name);
                      }}
                      onChange={(keyword) => {
                        setBuildingManagerName(keyword as string);
                      }}
                      onSearch={debounce(handleSearchBuildingManagers, 1000)}
                      value={buildingManagerName}
                    >
                      <Input.Search size="large" />
                    </AutoComplete>
                  </div>
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <h6 className="font-semibold sm:w-full md:w-1/3 text-left sm:text-right">
              Strata Manager:
            </h6>
            <Form.Item
              label="Name"
              validateStatus={validateStatus(
                touched.strataManagerId,
                errors.strataManagerId
              )}
              help={touched.strataManagerId && errors.strataManagerId}
            >
              <AutoComplete
                notFoundContent={<Spin />}
                dropdownMatchSelectWidth={false}
                dataSource={toStrataManagerOptions(strataManagers)}
                placeholder="Search to select strata manager"
                onSelect={(value) => {
                  const selectedStrataManager: any = strataManagers.find(
                    (item: any) => item.id === value
                  );
                  change("strataManagerId", selectedStrataManager.id);
                  change(
                    "strataManagerName",
                    selectedStrataManager.strataManagerName
                  );
                  change(
                    "strataManagerEmail",
                    selectedStrataManager.strataManagerEmail
                  );
                  change(
                    "strataManagerPhone",
                    selectedStrataManager.strataManagerPhone
                  );
                  setStrataManagerName(selectedStrataManager.strataManagerName);
                  setStrataManagerId(selectedStrataManager.id);
                }}
                onChange={(keyword) => {
                  setStrataManagerName(keyword as string);
                  change("strataManagerId", undefined);
                  change("strataManagerName", undefined);
                  change("strataManagerEmail", undefined);
                  change("strataManagerPhone", undefined);
                }}
                onSearch={debounce(handleSearchStrataManagers, 1000)}
                value={strataManagerName}
              >
                <Input.Search size="large" />
              </AutoComplete>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Building Manager Phone"
              validateStatus={validateStatus(
                touched.buildingManagerPhone,
                errors.buildingManagerPhone
              )}
              help={touched.buildingManagerPhone && errors.buildingManagerPhone}
              className="whitespace-label"
            >
              <Field name="buildingManagerPhone">
                {({ input }) => (
                  <Input
                    type="text"
                    placeholder="Building Manager Phone"
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Strata Manager Phone"
              validateStatus={validateStatus(
                touched.strataManagerPhone,
                errors.strataManagerPhone
              )}
              help={touched.strataManagerPhone && errors.strataManagerPhone}
              className="whitespace-label"
            >
              <Field name="strataManagerPhone">
                {({ input }) => (
                  <Input
                    type="text"
                    placeholder="Strata Manager Phone"
                    disabled={true}
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Building Manager Email"
              validateStatus={validateStatus(
                touched.buildingManagerEmail,
                errors.buildingManagerEmail
              )}
              help={touched.buildingManagerEmail && errors.buildingManagerEmail}
              className="whitespace-label"
            >
              <Field name="buildingManagerEmail">
                {({ input }) => (
                  <Input
                    disabled={role === 2}
                    type="text"
                    placeholder="Building Manager Email"
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label="Strata Manager Email"
              validateStatus={validateStatus(
                touched.strataManagerEmail,
                errors.strataManagerEmail
              )}
              help={touched.strataManagerEmail && errors.strataManagerEmail}
              className="whitespace-label"
            >
              <Field name="strataManagerEmail">
                {({ input }) => (
                  <>
                    <Input
                      type="text"
                      placeholder="Strata Manager Email"
                      {...input}
                      disabled={true}
                    />
                    {(!values?.strataManagerId ||
                      !values?.strataManagerEmail) && (
                      <span className="ant-form-item-required">
                        Please confirm the details of the Strata Manager are
                        correct in Xero
                      </span>
                    )}
                  </>
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <h1 className="font-semibold text-xl">Documents:</h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Documents"
              validateStatus={validateStatus(
                touched.documents,
                errors.documents
              )}
              help={touched.documents && errors.documents}
            >
              {values.documents && (
                <List
                  dataSource={values.documents}
                  renderItem={(item: any) => (
                    <List.Item key={item.id}>
                      <List.Item.Meta title={item.name} />
                      <ActionComponent
                        deleteClick={() => {
                          deleteDocumentClick(item);
                        }}
                        downloadClick={() => downloadDocumentClick(item)}
                      />
                    </List.Item>
                  )}
                />
              )}
              <Upload
                multiple={true}
                fileList={values.newDocuments}
                listType="picture"
                onChange={handleUpload}
                beforeUpload={() => false}
              >
                <Button>
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <h1 className="font-semibold text-xl">Notes:</h1>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} md={12}>
            <Form.Item
              label="Notes"
              validateStatus={validateStatus(touched.notes, errors.notes)}
              help={touched.notes && errors.notes}
            >
              <Field name="notes">
                {({ input }) => (
                  <TextArea rows={4} placeholder="Notes" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={(pristine || submitting) && handleChangeFile()}
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

export default BuildingEditForm;
