import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import { getRouterHistory } from "../../../_store";
import {
  getVehicles,
  getVehicle,
  deleteVehicle,
  updateVehicle,
  createVehicle,
} from "../../../_services/vehicleService";
import VehicleEditComponent from "./vehicle-edit.component";

const VehicleContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingVehicle, setEditingVehicle] = useState<any>(null);
  const [searchData, setSearchData] = useState({ make: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getVehicles,
      searchData.make,
      props.tenantId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.vehicles);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, props.tenantId, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getVehicle, record.tenantId, record.id).then(
      (response: any) => {
        setEditingVehicle({ ...response });
      }
    );
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(
        dispatch,
        deleteVehicle,
        record.tenantId,
        record.id
      );
      message.info(`Deleted vehicle ${record.make}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingVehicle({});
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingVehicle.id) {
        await applyApiService(
          dispatch,
          updateVehicle,
          props.tenantId,
          editingVehicle.id,
          values
        );
        message.info(`Updated vehicle ${values.make}`);
        const index = data.findIndex((i: any) => i.id === editingVehicle.id);
        data[index] = values;
        setEditingVehicle(undefined);
      } else {
        var newVehicle = await applyApiService(
          dispatch,
          createVehicle,
          props.tenantId,
          values
        );
        message.info(`Created vehicle ${values.make}`);
        setData([newVehicle, ...data]);
        setEditingVehicle(undefined);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingVehicle(undefined);
  };

  const searchVehicles = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ make: "" });
    } else {
      setSearchData({ make: e.target.value });
    }
  };

  const columns = [
    {
      title: "Vehicle Make",
      dataIndex: "make",
    },
    {
      title: "Vehicle Model",
      dataIndex: "model",
    },
    {
      title: "Vehicle Colour",
      dataIndex: "colour",
    },
    {
      title: "Vehicle Registration",
      dataIndex: "registration",
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Vehicle"
            handleSearch={searchVehicles}
            canAdd={true}
            addText="Add Vehicle"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingVehicle && (
        <VehicleEditComponent
          handleSubmit={handleConfirmEdit}
          editingVehicle={editingVehicle}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default VehicleContainer;
