import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import { getRouterHistory } from "../../../_store";
import {
  getAsset,
  deleteAssets,
  createAssets,
  updateAssets,
  getAssets,
} from "../../../_services/assetsService";
import AssetsEditComponent from "./asset-edit.component";

const AssetsContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingAssets, setEditingAssets] = useState<any>(null);
  const [searchData, setSearchData] = useState({ name: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getAssets,
      searchData.name,
      props.buildingId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.assets);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, props.buildingId, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getAsset, record.id).then((response: any) => {
      setEditingAssets({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteAssets, record.id);
      message.info(`Deleted assets ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingAssets({});
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingAssets.id) {
        await applyApiService(dispatch, updateAssets, editingAssets.id, values);
        message.info(`Updated assets ${values.name}`);
        const index = data.findIndex((i: any) => i.id === editingAssets.id);
        data[index] = values;
        setEditingAssets(undefined);
      } else {
        values.buildingId = props.buildingId;
        var newAssets = await applyApiService(dispatch, createAssets, values);
        message.info(`Created assets ${values.name}`);
        setData([newAssets, ...data]);
        setEditingAssets(newAssets);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingAssets(undefined);
  };
  const searchAssets = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ name: "" });
    } else {
      setSearchData({ name: e.target.value });
    }
  };

  const columns = [
    {
      title: "Asset Name",
      dataIndex: "name",
    },
    {
      title: "Location",
      dataIndex: "locationDetails",
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Assets"
            handleSearch={searchAssets}
            canAdd={true}
            addText="Add Asset"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingAssets && (
        <AssetsEditComponent
          handleSubmit={handleConfirmEdit}
          editingAssets={editingAssets}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default AssetsContainer;
