import moment from "moment";
export const parseDate = (date: Date) =>
  date ? moment(date, "YYYY-MM-DD") : null;
export const getKeepOffSet = (date: Date) =>
  date && typeof date.toISOString === "function" ? date.toISOString() : date;

export const toLocalDate = (date: Date) => {
  let rs = moment.utc(date).local();
  // if (isLocal) {
  //   rs = moment(date);
  // } else {
  //   rs = moment.utc(date).local();
  // }
  return rs;
};
