class Pagination {
  defaultPageSize: number;
  showSizeChanger: boolean;
  pageSizeOptions: string[];

  constructor() {
    this.defaultPageSize = 25;
    this.showSizeChanger = true;
    this.pageSizeOptions = ["25", "50", "100"];
  }
}

export const tableConfig = {
  Pagination: new Pagination(),
};

export const listCardConfig = {
  PAGINATION: {
    defaultPageSize: 9,
    showSizeChanger: true,
    pageSizeOptions: ["9", "18", "27"],
  },
};

// export * from "./enums";
