import axios from "axios";
import Cookies from "universal-cookie";
import { checkVersion } from "../versionNotification";
import { getRouterHistory } from "../_store";

class BaseAxios {
  request: any;
  constructor() {
    this.request = axios.create();
    const cookies = new Cookies();
    var token = cookies.get("token");
    if (token) {
      this.request.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    this.request.interceptors.response.use((response: any) => {
      if (response.status === 401 || response.status === 403) {
        const history = getRouterHistory();
        history.push("/login");
      } else {
        checkVersion(response);
        return response;
      }
    });
  }

  isLogedIn = () => {
    const cookies = new Cookies();
    var token = cookies.get("token");
    return token !== undefined;
  };

  reset = (token: string) => {
    if (token) {
      this.request.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  };
}

export default new BaseAxios();
