import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import AppNavigation from "./AppNavigation";
import LoginContainer from "./pages/login.container";
import store, { getRouterHistory } from "./_store";
import theme from "./_style/theme";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={getRouterHistory()}>
          <Switch>
            <Route exact path="/" component={LoginContainer}></Route>
            <Route exact path="/login" component={LoginContainer}></Route>
            <AppNavigation />
          </Switch>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
