import { Alert, Form, Input, Row } from "antd";
import React from "react";
import { Field } from "react-final-form";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../../_components/controls/button.component";
import { ErrorRowStyled } from "../../../_components/controls/grid.component";
import { formItemLayout } from "../../../_style/form";
import { validateStatus } from "../../../_utils/validation";
import * as Yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import withLoading from "../../../_components/controls/loading.component";

export const validationSchema = Yup.object({
  name: Yup.string().required("Asset name is required"),
  locationDetails: Yup.string().required("Location details is required"),
});

const AssetsEditForm = withLoading(
  ({
    handleSubmit,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: { change },
    values,
    rest,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="Asset Name"
          validateStatus={validateStatus(touched.name, errors.name)}
          help={touched.name && errors.name}
        >
          <Field name="name">
            {({ input }) => (
              <Input type="text" placeholder="Asset Name" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Location Details"
          validateStatus={validateStatus(
            touched.locationDetails,
            errors.locationDetails
          )}
          help={touched.locationDetails && errors.locationDetails}
        >
          <Field name="locationDetails">
            {({ input }) => (
              <Input type="text" placeholder="Location Details" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Notes"
          validateStatus={validateStatus(touched.notes, errors.notes)}
          help={touched.notes && errors.notes}
        >
          <Field name="notes">
            {({ input }) => (
              <TextArea rows={4} placeholder="Notes" {...input} />
            )}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

export default AssetsEditForm;
