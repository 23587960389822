import moment from "moment";
import { message } from "antd";
import React, { useState, useEffect } from "react";
import LayoutComponent from "../../_components/layout.component";
import withLoading from "../../_components/controls/loading.component";
import { getFile } from "../../_services/fileService";
import {
  getDefectType,
  getLocation,
  getDefectStatus,
  getRiskRating,
} from "../../_const/enums";

const ReportDefectContainer = withLoading(
  (props: any, arr: any[], url: any, document: any) => {
    const record = props.history.location.state;
    const dateSchedule = moment(record.date).format("DD-MM-YYYY");
    const [data, setData] = useState<any>("");
    arr = [];

    useEffect(() => {
      if (record.documents.length > 0) {
        handleGetURL();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleGetURL = async () => {
      for (document of record.documents) {
        url = await getFile(document.id);
        try {
          arr.push(url);
        } catch (error) {
          message.error(JSON.parse(error).message);
        }
      }
      setData(arr);
    };

    return (
      <LayoutComponent>
        <div className="mx-64 report-defect">
          <h1 className="text-5xl font-medium mt-10">Marigold Defect Report</h1>
          <h1 className="text-3xl font-semibold">Title: {record.title} </h1>
          <h1 className="text-xl mt-4 font-semibold">
            Risk Rating: {getRiskRating(record.rating).name}{" "}
          </h1>
          <h1 className="text-lg font-semibold mt-2">
            {" "}
            User name: {record.name}
          </h1>
          <h1 className="text-lg font-semibold">
            {" "}
            Date/time scheduled: {dateSchedule}
          </h1>
          <table className="report-table my-4 w-full">
            <tr>
              <th className="text-base">
                Type (defect/repair): {getDefectType(record.defectType).name}
              </th>
              <th className="text-base">
                Location: {getLocation(record.location).name}
              </th>
              <th className="text-base">
                Status: {getDefectStatus(record.status).name}
              </th>
            </tr>
          </table>
          <h1 className="text-lg font-semibold"> Notes: </h1>
          <span className="text-base block w-3/4">{record.notes}</span>
          <h1 className="text-lg font-semibold mt-4"> Photos:</h1>
          <div>
            <div className="image-wrapper mb-8">
              {data &&
                data.map((item: any) => {
                  return (
                    <img
                      key={item}
                      alt=""
                      src={item}
                      width="600px"
                      className="mr-6 mt-4"
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </LayoutComponent>
    );
  }
);

export default ReportDefectContainer;
