import React, { useCallback, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import { applyApiService } from "../../_services/apiService";
import SearchScheduleComponent from "./search.schedule.component";
import moment from "moment";
import withLoading from "../../_components/controls/loading.component";
import { message, Modal, Spin } from "antd";
import AddScheduleForm from "./schedule-edit.component";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { createEvent, getEvents } from "../../_services/buildingService";
import { toLocalDate } from "../../_utils/dateTime";
const localizer = momentLocalizer(moment);

const ScheduleContainer = withLoading((props: any) => {
  const dispatch = useDispatch();
  const [events, setEvents] = useState<any[]>([]);
  const [editingEvent, setEditingEvent] = useState<any>(undefined);

  const [start, setFromDate] = useState(
    moment().subtract(30, "days").startOf("day")
  );
  const [end, setToDate] = useState(moment().add(7, "days").endOf("day"));

  const handleSubmit = useCallback(
    (values: any) => {
      setFromDate(values.start);
      setToDate(values.end);
      applyApiService(
        dispatch,
        getEvents,
        props.rest.buildingId,
        values.start,
        values.end
      ).then((items: any[]) => {
        let events: any[] = [];
        items.forEach((item) => {
          events.push({
            id: item.googleEventId,
            title: item.title,
            allDay: false,
            start: toLocalDate(item.fromDate),
            end: toLocalDate(item.toDate),
            location: item.location,
            description: item.description,
            users: item.users,
          });
        });
        setEvents(events);
      });
    },
    [dispatch, props.rest]
  );

  const createEventHandle = useCallback(
    (values: any) => {
      applyApiService(
        dispatch,
        createEvent,
        props.rest.buildingId,
        values.title,
        values.description,
        values.location,
        values.start,
        values.end,
        values.selectedUsers.map((item: any) => item.id)
      ).then(() => {
        setEditingEvent(undefined);
        applyApiService(
          dispatch,
          getEvents,
          props.rest.buildingId,
          start,
          end
        ).then((items: any[]) => {
          let events: any[] = [];
          items.forEach((item) => {
            events.push({
              id: item.googleEventId,
              title: item.title,
              allDay: false,
              start: toLocalDate(item.fromDate),
              end: toLocalDate(item.toDate),
              location: item.location,
              description: item.description,
              users: item.users,
            });
          });
          setEvents(events);
        });
      });
    },
    [dispatch, end, props.rest.buildingId, start]
  );

  const selectEvent = useCallback(
    (event) => {
      let selectingEvents = events.filter((i) => i.id === event.id);
      if (selectingEvents && selectingEvents.length > 0) {
        selectingEvents[0].editMode = true;
        setEditingEvent(selectingEvents[0]);
      } else {
        message.error("You are selecting invalid event.");
      }
    },
    [events]
  );

  const showEventForm = useCallback(() => {
    let start = moment().add(1, "hours");
    start.add(-start.minute(), "minutes");

    let end = moment().add(1, "hours");
    end.add(-end.minute(), "minutes").add(30, "minutes");
    setEditingEvent({ start: start, end: end });
  }, []);

  useEffect(() => {
    handleSubmit({ start: start, end: end });
  }, [start, handleSubmit, end]);

  return (
    <>
      <FinalForm
        render={(props: any) => (
          <SearchScheduleComponent
            setShowCreateEventForm={showEventForm}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{
          start: start,
          end: end,
        }}
      />
      {/* <TableComponent
        columns={MaintenanceScheduledColumns}
        count={count}
        data={data}
      ></TableComponent> */}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={(event: any) => selectEvent(event)}
      />
      {editingEvent && (
        <Modal
          title="Add Google Event"
          visible={editingEvent !== undefined}
          footer={null}
          closable={true}
          onCancel={() => {
            setEditingEvent(undefined);
          }}
          width="700px"
        >
          <Spin spinning={props.isLoading}>
            <FinalForm
              render={(props: any) => (
                <AddScheduleForm
                  handleCancel={() => {
                    setEditingEvent(undefined);
                  }}
                  buildingId={props.buildingId}
                  {...props}
                />
              )}
              // validate={(values) => validate(values, validationSchema)}
              onSubmit={createEventHandle}
              initialValues={editingEvent}
            />
          </Spin>
        </Modal>
      )}
    </>
  );
});

export default ScheduleContainer;
