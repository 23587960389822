import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "./app";
import { addExtensions } from "../_utils/history";
const initialState = {};
const history = addExtensions(createBrowserHistory());
export const getRouterHistory = () => history;

const configureStore = (preloadedState: any) => {
  const composeEnhancers =
    process.env.NODE_ENV === "development"
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose //add support for Redux dev tools
      : compose;
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history) // for dispatching history actions
      )
    )
  );

  return store;
};
export default configureStore(initialState);
