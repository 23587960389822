import moment from "moment";
import React from "react";
import { getWorkOrderStatus } from "../../_const/enums";

export const MaintenanceWorkRequestsColumns = [
  {
    title: "Date/time scheduled",
    dataIndex: "date",
    render: (text: string, record: any) => {
      return <>{moment(record.date).format("DD-MM-YYYY")}</>;
    },
  },
  {
    title: "User name",
    dataIndex: "name",
  },
  {
    title: "Client",
    dataIndex: "clientName",
  },
  {
    title: "Building",
    dataIndex: "building",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: string, record: any) => {
      return <>{getWorkOrderStatus(record.status).name}</>;
    },
  },
  {
    title: "Strata Plan Number",
    dataIndex: "strataPlanNumber",
  },
  {
    title: "Strata Manager",
    dataIndex: "strataManager",
  },
  {
    title: "Work Request details",
    dataIndex: "workRequestDetail",
  },
];
