import { Alert, Form, Input, Modal, Row, DatePicker } from "antd";
import React from "react";
import { Field, Form as FinalForm } from "react-final-form";
import * as Yup from "yup";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../../_components/controls/button.component";
import { ErrorRowStyled } from "../../../_components/controls/grid.component";
import { formItemLayout } from "../../../_style/form";
import { validate, validateStatus } from "../../../_utils/validation";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import withLoading from "../../../_components/controls/loading.component";

const validationSchema = Yup.object({
  title: Yup.string().required("Service history name is required"),
  description: Yup.string().required("Description is required"),
  date: Yup.date().required("Date is required"),
});

const ServiceHistoryEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: {
      change,
      mutators: { push },
    },
    values,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    const dateFormat = "DD/MM/YYYY";

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="Title"
          validateStatus={validateStatus(touched.title, errors.title)}
          help={touched.title && errors.title}
        >
          <Field name="title">
            {({ input }) => (
              <Input type="text" placeholder="Title" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Description"
          validateStatus={validateStatus(
            touched.description,
            errors.description
          )}
          help={touched.description && errors.description}
        >
          <Field name="description">
            {({ input }) => (
              <TextArea rows={4} placeholder="Description" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Date"
          validateStatus={validateStatus(touched.date, errors.date)}
          help={touched.date && errors.date}
        >
          <Field name="date">
            {({ input }) => (
              <DatePicker
                {...input}
                defaultValue={moment("01/01/2020", dateFormat)}
                format={dateFormat}
              />
            )}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const ServiceHistoryEditComponent = ({
  editingHistory,
  handleCancel,
  handleSubmit,
}: {
  editingHistory: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  return (
    <Modal
      title={editingHistory.id ? "Edit Service History" : "Add Service History"}
      visible={editingHistory !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <FinalForm
        render={(props) => (
          <ServiceHistoryEditForm
            rest={{
              handleCancel: handleCancel,
            }}
            {...props}
          />
        )}
        validate={(values) => validate(values, validationSchema)}
        onSubmit={handleSubmit}
        initialValues={editingHistory}
      />
    </Modal>
  );
};

export default ServiceHistoryEditComponent;
