import { parse, stringify } from "query-string";

export const addExtensions = (history: any) => {
  history.pagingPush = (offset: number, limit: number) => {
    const updatedUrl = getUpdatedUrl(
      history.location.pathname,
      history.location.search,
      {
        offset: offset,
        limit: limit,
      }
    );
    history.push(updatedUrl);
  };

  history.getPaginationParams = (defaultLimit: number) => {
    const searchParams = parse(history.location.search);
    return searchParams && searchParams.offset && searchParams.limit
      ? {
          offsetParam: parseInt(searchParams.offset as string),
          limitParam: parseInt(searchParams.limit as string),
        }
      : { offsetParam: 0, limitParam: defaultLimit || 10 };
  };

  return history;
};

export const getPaginationParams = (searchQuery: any, defaultLimit: number) => {
  const searchParams = parse(searchQuery);
  return searchParams && searchParams.offset && searchParams.limit
    ? {
        offsetParam: parseInt(searchParams.offset as string),
        limitParam: parseInt(searchParams.limit as string),
      }
    : { offsetParam: 0, limitParam: defaultLimit || 25 };
};

export const getTabQueryParam = (searchQuery: any) => {
  const searchParams = parse(searchQuery);
  return searchParams ? searchParams.tab : null;
};

export const getUpdatedUrl = (
  path: string,
  currentSearch: any,
  params: any
) => {
  const currentParams = parse(currentSearch);
  return `${path}?${stringify({ ...currentParams, ...params })}`;
};
