import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getTasks(
  keyword: string,
  serviceId: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/services/${serviceId}/tasks?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        tasks: response.data.items,
        count: response.data.total,
      };
    });
}

export function getTask(serviceId: string, taskId: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/services/${serviceId}/tasks/${taskId}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteTask(serviceId: string, taskId: string) {
  return baseAxios.request.delete(
    `${baseUrl}v1/services/${serviceId}/tasks/${taskId}`
  );
}

export function updateTask(serviceId: string, taskId: string, service: object) {
  return baseAxios.request.put(
    `${baseUrl}v1/services/${serviceId}/tasks/${taskId}`,
    service
  );
}

export function createTask(serviceId: string, service: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/services/${serviceId}/tasks`, service)
    .then((response: any) => response.data);
}
