import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { selectors as apiStatusSelectors } from "../../_store/app/api";
const withLoading = (WrappedComponent: any) => {
  const WrappedComponentWithLoading = ({
    isLoading,
    ...rest
  }: {
    isLoading: boolean;
    rest: any;
  }) => (
    <Spin spinning={isLoading}>
      <WrappedComponent {...rest} isLoading={isLoading} />
    </Spin>
  );
  const mapStateToProps = (state: any) => ({
    isLoading: apiStatusSelectors.inProgress(state),
  });
  return connect(mapStateToProps)(WrappedComponentWithLoading);
};

export default withLoading;
