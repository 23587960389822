import { Table } from "antd";
import React, { useCallback } from "react";
import { tableConfig } from "../../_const";
import { TableData } from "../../_models/table-data.model";
import { getRouterHistory } from "../../_store";

const TableComponent = (data: TableData) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const getCurrentPage = (offset: number, limit: number) => offset / limit + 1;

  const onPageChange = useCallback(
    (page: number, pagesize?: number | undefined) => {
      let size = tableConfig.Pagination.defaultPageSize;
      if (pagesize !== undefined) {
        size = pagesize;
      }
      const offset = page === 0 ? 0 : (page - 1) * size;
      history.pagingPush(offset, pagesize);
    },
    [history]
  );
  return (
    <Table
      columns={data.columns}
      dataSource={data.data}
      pagination={{
        ...tableConfig.Pagination,
        current: getCurrentPage(offsetParam, limitParam),
        pageSize: limitParam,
        total: data.count,
        onChange: onPageChange,
        onShowSizeChange: onPageChange,
      }}
      rowKey="id"
    />
  );
};

export default TableComponent;
