import moment from "moment";
import React from "react";
import {
  getDefectStatus,
  getDefectType,
  getRiskRating,
} from "../../_const/enums";
import { EyeOutlined } from "@ant-design/icons";
import { getRouterHistory } from "../../_store";
import { List } from "antd";

const history = getRouterHistory();

export const DefectsRepairsLoggedColumns = [
  {
    title: "Date/time scheduled",
    dataIndex: "date",
    render: (text: string, record: any) => {
      return <>{moment(record.date).format("DD-MM-YYYY")}</>;
    },
  },
  {
    title: "User name",
    dataIndex: "name",
  },
  {
    title: "Type (defect/repair)",
    dataIndex: "defectType",
    render: (text: string, record: any) => {
      return <>{getDefectType(record.defectType).name}</>;
    },
  },
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Location",
    dataIndex: "location",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text: string, record: any) => {
      return <>{getDefectStatus(record.defectType).name}</>;
    },
  },
  {
    title: "Risk Rating",
    dataIndex: "rating",
    render: (text: string, record: any) => {
      return <>{getRiskRating(record.rating).name}</>;
    },
  },
  {
    title: "Photos",
    dataIndex: "documents",
    render: (documents: any[]) => {
      return (
        documents &&
        documents.length > 0 && (
          <List
            dataSource={documents}
            renderItem={(item: any) => (
              <List.Item key={item.id}>
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
        )
      );
    }
  },
  {
    title: "Notes",
    dataIndex: "notes",
    className: "w-4/12",
  },
  {
    title: "View Report",
    dataIndex: "",
    render: (record: any) => {
      return (
        <>
          <EyeOutlined onClick={()=> openReport(record)} />    
        </>
      );
    },
  },
];

const openReport = (record: any) => {
  history.push("/reports/defects", record);
};