import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import { getRouterHistory } from "../../../_store";
import TasksEditComponent from "./tasks-edit.component";
import {
  getTasks,
  getTask,
  deleteTask,
  updateTask,
  createTask,
} from "../../../_services/taskService";

const TasksContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingTasks, setEditingTasks] = useState<any>(null);
  const [searchData, setSearchData] = useState({ name: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getTasks,
      searchData.name,
      props.serviceId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.tasks);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, props.serviceId, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getTask, props.serviceId, record.id).then(
      (response: any) => {
        setEditingTasks({ ...response });
      }
    );
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteTask, props.serviceId, record.id);
      message.info(`Deleted task ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingTasks({});
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingTasks.id) {
        await applyApiService(
          dispatch,
          updateTask,
          props.serviceId,
          editingTasks.id,
          values
        );
        message.info(`Updated task ${values.name}`);
        const index = data.findIndex((i: any) => i.id === editingTasks.id);
        data[index] = values;
        setEditingTasks(undefined);
      } else {
        values.ServiceId = props.serviceId;
        var newTasks = await applyApiService(
          dispatch,
          createTask,
          props.serviceId,
          values
        );
        message.info(`Created task ${values.name}`);
        setData([newTasks, ...data]);
        setEditingTasks(undefined);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingTasks(undefined);
  };

  const searchTask = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ name: "" });
    } else {
      setSearchData({ name: e.target.value });
    }
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Task"
            handleSearch={searchTask}
            canAdd={true}
            addText="Add Task"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingTasks && (
        <TasksEditComponent
          handleSubmit={handleConfirmEdit}
          editingTasks={editingTasks}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default TasksContainer;
