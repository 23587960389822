import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import {
  createDefect,
  deleteDefect,
  getDefect,
  getDefects,
  updateDefect,
} from "../../../_services/defectService";
import { getRouterHistory } from "../../../_store";
import DefectEditComponent from "./defect-edit.component";
import {
  DefectsType,
  getDefectStatus,
  getDefectType,
} from "../../../_const/enums";
import { uploadFile } from "../../../_services/fileService";
import DefectSearchComponent from "./defect-search.component ";

const DefectContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingDefect, setEditingDefect] = useState<any>(null);
  const [searchData, setSearchData] = useState({ title: "" });
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getDefects,
      searchData.title,
      props.buildingId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.defects);
      setFilterData(response.defects);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, props.buildingId, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getDefect, record.id).then((response: any) => {
      setEditingDefect({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteDefect, record.id);
      message.info(`Deleted defects ${record.title}`);
      const deletedData = data.filter((i: any) => i.id !== record.id);
      setData(deletedData);
      if (selectedStatus === 0) setFilterData(deletedData);
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingDefect({ defectType: DefectsType });
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (values.newDocuments) {
        const items = await uploadFile(values.newDocuments);
        if (!values.documents) {
          values.documents = [];
        }
        items.forEach((item: any) => {
          values.documents.push(item);
        });
      }
      if (editingDefect.id) {
        await applyApiService(dispatch, updateDefect, editingDefect.id, values);
        message.info(`Updated defects and repairs ${values.title}`);
        const index = data.findIndex((i: any) => i.id === editingDefect.id);
        data[index] = values;
        setEditingDefect(undefined);
      } else {
        values.buildingId = props.buildingId;
        var newDefect = await applyApiService(dispatch, createDefect, values);
        message.info(`Created defects and repairs ${values.title}`);
        setData([newDefect, ...data]);
        if (selectedStatus === 0) setFilterData([newDefect, ...data]);
        setEditingDefect(undefined);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingDefect(undefined);
  };

  const handleSearchStatus = (values: any) => {
    if (values === 0) {
      setSelectedStatus(0);
      setFilterData(data);
    } else {
      let newData: any;
      newData = data.filter((item: any) => values === item.status);
      setFilterData(newData);
      setSelectedStatus(values);
    }
  };

  const searchDefects = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ title: "" });
    } else {
      setSearchData({ title: e.target.value });
    }
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "defectType",
      render: (text: string, record: any) => {
        return <>{getDefectType(record.defectType).name}</>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        return <>{getDefectStatus(record.status).name}</>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search"
            handleSearch={searchDefects}
            canAdd={true}
            addText="Add +"
            addClick={addClick}
            addFilter={
              <DefectSearchComponent handleFilter={handleSearchStatus} />
            }
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={filterData}
      ></TableComponent>

      {editingDefect && (
        <DefectEditComponent
          handleSubmit={handleConfirmEdit}
          editingDefect={editingDefect}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default DefectContainer;
