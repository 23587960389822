import React from "react";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
const { confirm } = Modal;

const ActionComponent = ({
  editClick,
  deleteClick,
  downloadClick,
}: {
  editClick?: any;
  deleteClick?: any;
  downloadClick?: any;
}) => {
  const handleDelete = () => {
    confirm({
      title:
        "Do you really want to delete this record? This process cannot be undone",
      async onOk() {
        deleteClick();
      },
    });
  };
  return (
    <>
      {editClick && (
        <EditOutlined onClick={editClick} className="block lg:inline" />
      )}
      {downloadClick && <DownloadOutlined onClick={downloadClick} />}
      {handleDelete && (
        <DeleteOutlined
          className="mx-5 mt-2 lg:mt-0 block lg:inline"
          onClick={handleDelete}
        />
      )}
    </>
  );
};

export default ActionComponent;
