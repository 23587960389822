import { Button, notification } from "antd";
import React from "react";
import { appVersion } from "./_services/endpoints";

let notifing = false;
let BuildHeader = "x-build";

export const newBuild = (build: any, callbackSkipBuild: any) => {
  if (notifing) return;
  notifing = true;
  const key = `version-notification-${build}`;
  const skip = () => {
    notification.close(key);
    callbackSkipBuild(build);
    notifing = false;
  };
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => window.location.reload()}
    >
      Reload
    </Button>
  );
  notification.open({
    message: "A new version is available",
    description:
      "We've just updated the application. Please reload the page to update",
    btn,
    key,
    onClose: skip,
    duration: null, // never close
  });
};

export const newMajorBuild = () => {
  if (notifing) return;
  notifing = true;
  const reload = () => {
    window.location.reload();
  };

  const btn = (
    <Button type="primary" size="small" onClick={reload}>
      Reload
    </Button>
  );

  notification.open({
    message: "A new major version is available",
    description: "Your browser will reload to install the new version",
    btn,
    onClose: reload,
    duration: 30,
  });
};

const createCheckVersionFn = () => {
  const skipBuild = (v: any) => {};
  return (response: any) => {
    let apiVersion = response.headers[BuildHeader];
    if (apiVersion) {
      if (appVersion !== apiVersion) {
        newBuild(response.headers[BuildHeader], skipBuild);
      }
    }
  };
};

export const checkVersion = createCheckVersionFn();
