import { Button, Col, DatePicker, Form, Row } from "antd";
import moment from "moment";
import React from "react";
import { Field } from "react-final-form";

const SearchScheduleComponent = ({
  handleSubmit,
  setShowCreateEventForm,
}: {
  handleSubmit: any;
  setShowCreateEventForm: any;
  form: any;
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={24} sm={6}>
          <Form.Item label="From Date">
            <Field name="fromDate">
              {({ input }) => (
                <DatePicker
                  style={{ width: "100%" }}
                  {...input}
                  value={
                    input.value
                      ? moment(input.value)
                      : moment().subtract(30, "days").startOf("day")
                  }
                  format="DD/MM/YYYY"
                />
              )}
            </Field>
          </Form.Item>
        </Col>
        <Col span={24} sm={6}>
          <Form.Item label="To Date">
            <Field name="toDate">
              {({ input }) => (
                <DatePicker
                  style={{ width: "100%" }}
                  {...input}
                  value={input.value ? moment(input.value) : moment()}
                  format="DD/MM/YYYY"
                />
              )}
            </Field>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} sm={24} md={12}>
          <Button
            type="default"
            htmlType="submit"
            className="btn-background-navy border shadow rounded h-auto mb-4"
          >
            Search
          </Button>
          <Button
            type="default"
            className="btn-background-navy border shadow rounded h-auto mb-4 ml-0 md:ml-2"
            onClick={setShowCreateEventForm}
          >
            Add maintenance schedule
          </Button>
          {/* <Button
            type="default"
            className="btn-background-navy border shadow rounded h-auto mb-4 ml-0 md:ml-2"
            onClick={() => window.open("https://calendar.google.com/calendar")}
          >
            Google Google Calendar
          </Button> */}
        </Col>
      </Row>
    </Form>
  );
};

export default SearchScheduleComponent;
