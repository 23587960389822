import React, { useCallback, useEffect, useState } from "react";
import LayoutComponent from "../../_components/layout.component";
import { Form as FinalForm } from "react-final-form";
import SearchComponent from "../../_components/search/search.component";
import TableComponent from "../../_components/table/table.component";
import { getRouterHistory } from "../../_store";
import { tableConfig } from "../../_const";
import { useDispatch } from "react-redux";
import { applyApiService } from "../../_services/apiService";
import {
  createClient,
  deleteClient,
  getClient,
  getClients,
  getXeroUrl,
  updateClient,
} from "../../_services/clientService";
import { getClientType, CommercialClientType } from "../../_const/enums";
import ActionComponent from "../../_components/action/action.component";
import ClientEditComponent from "./client-edit.component";
import { message } from "antd";
import { Link } from "react-router-dom";

const ClientContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [xeroUrl, setXeroUrl] = useState("");
  const [editingClient, setEditingClient] = useState<any>(null);
  const [searchData, setSearchData] = useState({ name: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values) => {
      setSearchData(searchData);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam, searchData]
  );

  useEffect(() => {
    applyApiService(dispatch, getXeroUrl).then((response: any) => {
      setXeroUrl(response);
    });
  }, [dispatch]);

  useEffect(() => {
    applyApiService(
      dispatch,
      getClients,
      searchData.name,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.clients);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getClient, record.id).then((response: any) => {
      setEditingClient({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteClient, record.id);
      message.info(`Deleted client ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingClient({ clientType: CommercialClientType });
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingClient.id) {
        await applyApiService(dispatch, updateClient, editingClient.id, values);
        message.info(`Updated client ${values.name}`);
        const index = data.findIndex((i: any) => i.id === editingClient.id);
        data[index] = values;
        setEditingClient(undefined);
      } else {
        var newClient = await applyApiService(dispatch, createClient, values);
        message.info(`Created client ${values.name}`);
        setData([newClient, ...data]);
        setEditingClient(undefined);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingClient(undefined);
  };

  const searchClients = (e: any) => {
    if (e.target.value === "") {
      debugger;
      setSearchData({ name: "" });
    } else {
      setSearchData({ name: e.target.value });
    }
  };

  const columns = [
    {
      title: "Client Type",
      dataIndex: "clientType",
      render: (text: string, record: any) => {
        return <>{getClientType(record.clientType)?.name}</>;
      },
    },
    {
      title: "Client Name",
      dataIndex: "companyName",
      render: (text: string, record: any) => {
        return <Link to={`/clients/${record.id}/buildings`}>{text}</Link>;
        // return <Link to={`/buildings?clientId=${record.id}`}>{text}</Link>;
      },
    },
    {
      title: "Contact Name",
      dataIndex: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => {
        return (
          <>
            <ActionComponent
              deleteClick={() => {
                deleteClick(record);
              }}
              editClick={() => editClick(record)}
            />
          </>
        );
      },
    },
  ];

  return (
    <LayoutComponent>
      <h1 className="mx-6 mt-4 text-lg">Client</h1>
      <p className="mx-6 mt-0">
        Commercial clients are created and managed in Xero.
      </p>
      {xeroUrl && (
        <p className="mx-6 mt-0">
          <a target="blank" href={xeroUrl}>
            You can allow Xero permission to get contact here.
          </a>
        </p>
      )}
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search for client"
            handleSearch={searchClients}
            canAdd={true}
            addText="Add Client"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{ name: "" }}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingClient && (
        <ClientEditComponent
          handleSubmit={handleConfirmEdit}
          editingClient={editingClient}
          handleCancel={handleCancel}
        />
      )}
    </LayoutComponent>
  );
};

export default ClientContainer;
