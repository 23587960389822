import { Form, Select, Row, Col } from "antd";
import React from "react";
import { Field } from "react-final-form";
import { formItemLayout } from "../../../_style/form";
import { DefectStatus } from "../../../_const/enums";

const DefectSearchComponent = ({ handleFilter }: { handleFilter: any }) => {
  const handleSelectStatus = (value: any) => {
    handleFilter(value);
  };

  return (
    <Form {...formItemLayout} layout="horizontal">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Choose Status">
            <Field name="status">
              {({ input }) => (
                <Select
                  style={{ height: 45 }}
                  defaultValue={0}
                  onSelect={handleSelectStatus}
                >
                  <Select.Option value={0} key={0}>
                    All Status
                  </Select.Option>
                  {DefectStatus.map((item: any) => (
                    <Select.Option value={item.key} key={item.key}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Field>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DefectSearchComponent;
