import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getServices(
  keyword: string,
  buildingId: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/services?keyword=${
        keyword ? keyword : ""
      }&buildingId=${buildingId}&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        services: response.data.items,
        count: response.data.total,
      };
    });
}

export function getService(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/services/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteServices(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/services/${id}`);
}

export function updateServices(id: string, service: object) {
  return baseAxios.request.put(`${baseUrl}v1/services/${id}`, service);
}

export function createServices(service: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/services`, service)
    .then((response: any) => response.data);
}
