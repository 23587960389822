import axios from "axios";
import moment from "moment";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getBuildings(
  keyword: string,
  offset: number,
  limit: number,
  clientId: string
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/buildings?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}&clientId=${clientId ?? ""}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        buildings: response.data.items,
        count: response.data.total,
      };
    });
}

export function getBuildingCalendars() {
  return baseAxios.request
    .get(`${baseUrl}v1/building-calendars`)
    .then((response: any) => {
      return response.data;
    });
}

export function getBuildingById(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/buildings/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteBuilding(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/buildings/${id}`);
}

export function updateBuilding(id: string, building: object) {
  return baseAxios.request.put(`${baseUrl}v1/buildings/${id}`, building);
}

export function createBuilding(building: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/buildings`, building)
    .then((response: any) => response.data);
}

export function getEvents(buildingId: string, fromDate: any, toDate: any) {
  return baseAxios.request
    .get(
      `${baseUrl}v1/buildings/${buildingId}/events?fromDate=${
        fromDate ? moment(fromDate).format("YYYY-MM-DD") : ""
      }&toDate=${toDate ? moment(toDate).format("YYYY-MM-DD") : ""}`,
      {
        buildingId,
        fromDate,
        toDate,
      }
    )
    .then((response: any) => {
      return response.data;
    });
}

export function createEvent(
  buildingId: string,
  title: string,
  description: string,
  location: string,
  fromDate: any,
  toDate: any,
  userIds: string[]
) {
  return baseAxios.request
    .post(`${baseUrl}v1/buildings/${buildingId}/events`, {
      buildingId,
      title,
      description,
      location,
      fromDate,
      toDate,
      userIds,
    })
    .then((response: any) => response.data);
}

export function updateEvent(
  ventId: string,
  buildingId: string,
  title: string,
  description: string,
  location: string,
  fromDate: any,
  toDate: any,
  userIds: string[]
) {
  return baseAxios.request
    .put(`${baseUrl}v1/buildings/${buildingId}/events/${ventId}`, {
      buildingId,
      title,
      description,
      location,
      fromDate,
      toDate,
      userIds,
    })
    .then((response: any) => response.data);
}

export async function sendEmailToAllTenants(
  buildingId: string,
  subject: string,
  content: string
) {
  return baseAxios.request
    .post(`${apiUrl}v1/buildings/${buildingId}/tenants/emails`, {
      buildingId,
      subject,
      content,
    })
    .then((response: any) => response.data);
}
