import { Collapse } from "antd";
import React, { useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { DrawerStyled } from "../../../_style/form";
import AssetsEditForm, { validationSchema } from "./asset-edit-form.component";
import { validate } from "../../../_utils/validation";
import ServiceHistoryContainer from "../history/service-history.container";

const AssetsEditComponent = ({
  editingAssets,
  handleCancel,
  handleSubmit,
}: {
  editingAssets: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([
    "asset",
    "service-history-details",
  ]);
  return (
    <DrawerStyled
      placement="right"
      title={editingAssets.id ? "Edit Asset" : "Add Asset"}
      visible={editingAssets !== null}
      closable={true}
      onClose={handleCancel}
    >
      <Collapse
        activeKey={activeKeys}
        onChange={(keys: any) => {
          setActiveKeys([...keys]);
        }}
      >
        <Collapse.Panel
          header={editingAssets.id ? editingAssets.name : "Add Asset"}
          key="asset"
        >
          <FinalForm
            render={(props) => (
              <AssetsEditForm
                rest={{
                  handleCancel: handleCancel,
                }}
                {...props}
              />
            )}
            onSubmit={handleSubmit}
            validate={(values) => validate(values, validationSchema)}
            initialValues={editingAssets}
          />
        </Collapse.Panel>
        {editingAssets && editingAssets.id && (
          <Collapse.Panel
            header="Service History Details"
            key="service-history-details"
          >
            <ServiceHistoryContainer assetId={editingAssets.id} />
          </Collapse.Panel>
        )}
      </Collapse>
    </DrawerStyled>
  );
};

export default AssetsEditComponent;
