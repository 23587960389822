import {
  Alert,
  AutoComplete,
  DatePicker,
  Form,
  Input,
  List,
  Row,
  Select,
  Spin,
  TimePicker,
} from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../_components/action/action.component";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../_components/controls/button.component";
import { ErrorRowStyled } from "../../_components/controls/grid.component";
import { applyApiService } from "../../_services/apiService";
import { getUsers } from "../../_services/userService";
import { formItemLayout } from "../../_style/form";
import { validateStatus } from "../../_utils/validation";

const AddScheduleForm = ({
  handleSubmit,
  handleCancel,
  submitError,
  dirtySinceLastSubmit,
  touched,
  errors,
  invalid,
  pristine,
  submitting,
  form: { change },
  ...rest
}: {
  handleSubmit: any;
  handleCancel: any;
  submitError: any;
  dirtySinceLastSubmit: any;
  touched?: any;
  errors: any;
  invalid: any;
  pristine: any;
  submitting: any;
  form: any;
  values: any;
  buildingId: any;
}) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  useEffect(() => {
    if (rest.values?.users) {
      setSelectedUsers(rest.values.users);
    }
  }, [rest.values]);

  const handleGetUsers = useCallback(
    (keyword: string) => {
      applyApiService(dispatch, getUsers, keyword, 0, 0).then(
        ({ users }: { users: [] }) => {
          setUsers(users || []);
        }
      );
    },
    [dispatch]
  );

  const toUserOptions = (users: any) =>
    users.map((user: any) => (
      <Select.Option key={user.id} value={user.id}>
        {user.name}
      </Select.Option>
    ));

  const deleteUserClick = useCallback(
    (document: any) => {
      let users = selectedUsers.filter((item: any) => item.id !== document.id);
      setSelectedUsers(users);
      change("selectedUsers", users);
    },
    [change, selectedUsers]
  );

  const onTimeStartChange = (e: any) => {
    let hours = 0;
    let minutes = 0;
    if (e != null) {
      hours = e.get("hour");
      minutes = e.get("minute");
    }

    let newDate = moment(rest.values.start);
    newDate.set("hour", hours);
    newDate.set("minute", minutes);
    change("start", newDate);
  };

  const onTimeEndChange = (e: any) => {
    let hours = 0;
    let minutes = 0;
    if (e != null) {
      hours = e.get("hour");
      minutes = e.get("minute");
    }

    let newDate = moment(rest.values.end);
    newDate.set("hour", hours);
    newDate.set("minute", minutes);
    change("end", newDate);
  };
  return (
    <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
      <Form.Item
        label="From Date"
        validateStatus={validateStatus(touched.title, errors.title)}
        help={touched.title && errors.title}
      >
        <Field name="start">
          {({ input }) => (
            <>
              <DatePicker
                style={{ width: "100%" }}
                getCalendarContainer={(trigger: any) => trigger.parentNode}
                {...input}
                value={input.value ? moment(input.value) : undefined}
                format="DD/MM/YYYY"
                disabledDate={(d) => !d || d.isBefore(new Date())}
              />
              <TimePicker
                {...input}
                use12Hours
                onChange={onTimeStartChange}
                value={input.value ? moment(input.value) : undefined}
                format="hh:mm a"
                hideDisabledOptions={true}
                disabledMinutes={() => {
                  let minutes = [];
                  for (let i = 0; i <= 59; i++) {
                    if (i % 5 !== 0) {
                      minutes.push(i);
                    }
                  }
                  return minutes;
                }}
              />
            </>
          )}
        </Field>
      </Form.Item>
      <Form.Item
        label="To Date"
        validateStatus={validateStatus(touched.title, errors.title)}
        help={touched.title && errors.title}
      >
        <Field name="end">
          {({ input }) => (
            <>
              <DatePicker
                style={{ width: "100%" }}
                getCalendarContainer={(trigger: any) => trigger.parentNode}
                {...input}
                value={input.value ? moment(input.value) : undefined}
                format="DD/MM/YYYY"
                disabledDate={(d) => !d || d.isBefore(new Date())}
              />
              <TimePicker
                {...input}
                use12Hours
                onChange={onTimeEndChange}
                value={input.value ? moment(input.value) : undefined}
                format="hh:mm a"
                hideDisabledOptions={true}
                disabledMinutes={() => {
                  let minutes = [];
                  for (let i = 0; i <= 59; i++) {
                    if (i % 5 !== 0) {
                      minutes.push(i);
                    }
                  }
                  return minutes;
                }}
              />
            </>
          )}
        </Field>
      </Form.Item>
      <Form.Item
        label="Title"
        validateStatus={validateStatus(touched.title, errors.title)}
        help={touched.title && errors.title}
      >
        <Field name="title">
          {({ input }) => <Input type="text" placeholder="Title" {...input} />}
        </Field>
      </Form.Item>
      <Form.Item
        label="Description"
        validateStatus={validateStatus(touched.description, errors.description)}
        help={touched.description && errors.description}
      >
        <Field name="description">
          {({ input }) => (
            <Input type="text" placeholder="Description" {...input} />
          )}
        </Field>
      </Form.Item>
      <Form.Item
        label="Location"
        validateStatus={validateStatus(touched.location, errors.location)}
        help={touched.location && errors.location}
      >
        <Field name="location">
          {({ input }) => (
            <Input type="text" placeholder="location" {...input} />
          )}
        </Field>
      </Form.Item>
      {!rest.values.editMode && (
        <Form.Item
          label="Search Attendee"
          validateStatus={validateStatus(touched.userId, errors.userId)}
          help={touched.userId && errors.userId}
        >
          <Field name="userId">
            {({ input }) => (
              <div {...input}>
                <AutoComplete
                  notFoundContent={<Spin />}
                  dropdownMatchSelectWidth={false}
                  dataSource={toUserOptions(users)}
                  placeholder="Search to select contact"
                  onSelect={(value) => {
                    const selectedUser: any = users.find(
                      (item: any) => item.id === value
                    );
                    selectedUsers.push(selectedUser);
                    setSelectedUsers(selectedUsers);
                    change("selectedUsers", selectedUsers);
                  }}
                  onChange={(keyword) => {
                    setUserName(keyword as string);
                  }}
                  onSearch={debounce(handleGetUsers, 1000)}
                  value={userName}
                >
                  <Input.Search size="large" />
                </AutoComplete>
              </div>
            )}
          </Field>
        </Form.Item>
      )}
      <Form.Item
        label="Attendees"
        validateStatus={validateStatus(touched.userId, errors.userId)}
        help={touched.userId && errors.userId}
      >
        <List
          dataSource={selectedUsers}
          renderItem={(item: any) => (
            <List.Item key={item.id}>
              <List.Item.Meta title={item.name} />
              {!rest.values.editMode && (
                <ActionComponent
                  deleteClick={() => {
                    deleteUserClick(item);
                  }}
                />
              )}
            </List.Item>
          )}
        />
      </Form.Item>
      {submitError && !dirtySinceLastSubmit && (
        <ErrorRowStyled>
          <Alert message={submitError} type="error" banner />
        </ErrorRowStyled>
      )}
      <Row>
        <hr className="mt-6" />
      </Row>
      <Row className="mt-4">
        <FloatRightButtonWithMargin
          htmlType="button"
          type="ghost"
          onClick={handleCancel}
        >
          Cancel
        </FloatRightButtonWithMargin>
        <FloatRightButton
          disabled={
            (invalid && !dirtySinceLastSubmit) || pristine || submitting
          }
          htmlType="submit"
          type="primary"
        >
          Save
        </FloatRightButton>
      </Row>
    </Form>
  );
};
export default AddScheduleForm;
