import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../../_components/action/action.component";
import SearchComponent from "../../../_components/search/search.component";
import TableComponent from "../../../_components/table/table.component";
import { tableConfig } from "../../../_const";
import { applyApiService } from "../../../_services/apiService";
import { getRouterHistory } from "../../../_store";
import ServiceHistoryEditComponent from "./service-history-edit.component";
import {
  getServiceHistory,
  deleteServiceHistory,
  updateServiceHistory,
  createServiceHistory,
  getServiceHistories,
} from "../../../_services/historyService";
import { toLocalDate } from "../../../_utils/dateTime";

const ServiceHistoryContainer = (props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingHistory, setEditingHistory] = useState<any>(null);
  const [searchData, setSearchData] = useState({ title: "" });
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getServiceHistory,
      searchData.title,
      props.assetId,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.history);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, props.assetId, searchData]);

  const editClick = (record: any) => {
    applyApiService(
      dispatch,
      getServiceHistories,
      props.assetId,
      record.id
    ).then((response: any) => {
      response.date = toLocalDate(response.date);
      setEditingHistory({ ...response });
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(
        dispatch,
        deleteServiceHistory,
        props.assetId,
        record.id
      );
      message.info(`Deleted service history ${record.title}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingHistory({});
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingHistory.id) {
        await applyApiService(
          dispatch,
          updateServiceHistory,
          props.assetId,
          editingHistory.id,
          values
        );
        message.info(`Updated service history ${values.title}`);
        const index = data.findIndex((i: any) => i.id === editingHistory.id);
        data[index] = values;
        setEditingHistory(undefined);
      } else {
        values.buildingId = props.assetId;
        var newHistory = await applyApiService(
          dispatch,
          createServiceHistory,
          props.assetId,
          values
        );
        message.info(`Created service history ${values.title}`);
        setData([newHistory, ...data]);
        setEditingHistory(undefined);
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingHistory(undefined);
  };
  const getDate = (date: any) => {
    return toLocalDate(date).format("DD/MM/YYYY");
  };
  const searchServiceHistory = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ title: "" });
    } else {
      setSearchData({ title: e.target.value });
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text: string, record: any) => {
        return <>{getDate(record.date)}</>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search Service History"
            handleSearch={searchServiceHistory}
            canAdd={true}
            addText="Add Service History"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{}}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>

      {editingHistory && (
        <ServiceHistoryEditComponent
          handleSubmit={handleConfirmEdit}
          editingHistory={editingHistory}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ServiceHistoryContainer;
