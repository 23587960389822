import { Button, Col, Form, Row } from "antd";
import React from "react";
import Search from "antd/lib/input/Search";

const SearchComponent = ({
  placeHolder,
  handleSubmit,
  canAdd,
  addText,
  addClick,
  additionalComponents,
  additionalFilterComponents,
  addFilter,
  handleSearch,
}: {
  placeHolder: string;
  handleSubmit: any;
  canAdd?: boolean;
  addText?: string;
  addClick?: any;
  additionalComponents?: any;
  additionalFilterComponents?: any;
  addFilter?: any;
  handleSearch?: any;
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16} className="mb-3 mt-2">
        <Col md={6} sm={24}>
          <Form.Item>
            <Search
              placeholder={placeHolder}
              style={{ height: 45 }}
              allowClear={true}
              onChange={(e) => {
                handleSearch(e);
              }}
            />
            {additionalFilterComponents}
          </Form.Item>
        </Col>
        <Col md={8} sm={24}>
          {addFilter}
        </Col>
        <Col md={10} sm={24}>
          {canAdd && (
            <Button
              className="btn-background-navy border shadow rounded h-auto md:float-right sm:float-left m-0"
              htmlType="button"
              onClick={addClick}
            >
              <span>{addText}</span>
            </Button>
          )}
          {additionalComponents}
        </Col>
      </Row>
    </Form>
  );
};

export default SearchComponent;
