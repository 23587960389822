import React from "react";
import { useDispatch } from "react-redux";
import { getRouterHistory } from "../_store";
import authActions from "../_store/app/auth/actions";
import baseAxios from "../_services/baseAxios";
import Cookies from "universal-cookie";
import { applyApiService } from "../_services/apiService";
import { getUserRole } from "../_services/userService";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { parse } from "querystring";
import { message } from "antd";
import {
  apiKey,
  appId,
  authDomain,
  databaseURL,
  messagingSenderId,
  projectId,
  storageBucket,
} from "../_services/endpoints";

const LoginContainer = () => {
  const dispatch = useDispatch();
  let config = {
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
  };

  return (
    <>
      <div className="btn-login h-screen font-sans">
        <div className="container mx-auto h-full flex justify-center items-center">
          <div className="w-2/3 sm:w-3/5">
            <h1 className="font-hairline mb-6 text-center text-white text-4xl">
              Login with Google
            </h1>
            <FirebaseAuthProvider {...config} firebase={firebase}>
              <div>
                <div className="border-navy-marigold p-8 border-t-12 bg-white rounded-lg shadow-lg">
                  <div className="flex justify-center items-center">
                    <button
                      onClick={() => {
                        const googleAuthProvider =
                          new firebase.auth.GoogleAuthProvider();
                        firebase
                          .auth()
                          .signInWithPopup(googleAuthProvider)
                          .then((response: any) => {
                            if (response.user) {
                              const history = getRouterHistory();
                              const cookies = new Cookies();
                              cookies.set("token", response.user.za, {
                                path: "/",
                              });
                              baseAxios.reset(response.user.za);
                              applyApiService(
                                dispatch,
                                getUserRole,
                                response.user.email
                              )
                                .then((userRole: any) => {
                                  let userData = {
                                    name: response.user.displayName,
                                    role: userRole,
                                  };
                                  dispatch(authActions.loginSuccess(userData));
                                  cookies.set("currentUser", userData, {
                                    path: "/",
                                  });
                                  const searchParams = parse(
                                    history.location.search
                                  );
                                  if (searchParams["?url"]) {
                                    let search = searchParams["?url"] as string;
                                    while (search.indexOf("/login") === 0) {
                                      search = search.replace("/login", "");
                                    }
                                    while (
                                      search.indexOf("?url=/login") === 0
                                    ) {
                                      search = search.replace(
                                        "?url=/login",
                                        ""
                                      );
                                    }
                                    while (search.indexOf("?url=") === 0) {
                                      search = search.replace("?url=", "");
                                    }
                                    history.push(decodeURI(search));
                                  } else {
                                    history.push("/clients");
                                  }
                                })
                                .catch((error: any) => {
                                  message.info(
                                    `You do not have permission to access with your account ${response.user.email}.`
                                  );
                                });
                            }
                          });
                      }}
                    >
                      Sign In with Google
                    </button>
                  </div>
                </div>
              </div>
            </FirebaseAuthProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginContainer;
