import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getTenants(
  keyword: string,
  buildingId: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/tenants?keyword=${
        keyword ? keyword : ""
      }&buildingId=${buildingId}&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        tenants: response.data.items,
        count: response.data.total,
      };
    });
}

export function getTenant(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/tenants/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteTenant(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/tenants/${id}`);
}

export function updateTenant(id: string, tenant: object) {
  return baseAxios.request.put(`${baseUrl}v1/tenants/${id}`, tenant);
}

export function createTenant(tenant: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/tenants`, tenant)
    .then((response: any) => response.data);
}

export function importTenant(
  clientId: string,
  buildingId: string,
  files: any[]
) {
  let formData = new FormData();
  formData.append(files[0].name, files[0]);
  return baseAxios
    .request({
      method: "post",
      url: `${baseUrl}v1/clients/${clientId}/buildings/${buildingId}/tenants/import`,
      data: formData,
      headers: {
        "content-type": `multipart/form-data;`,
      },
    })
    .then((response: any) => {
      return response.data;
    });
}
