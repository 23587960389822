import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import apiStatus from './api';

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    apiStatus,
    auth
  });
export default createRootReducer;
