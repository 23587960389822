const name = (state: any) => state.auth.currentUser.name;
const isAdmin = (state: any) => state.auth.currentUser.role === 1;
const currentRole = (state: any) => state.auth.currentUser.role;

export default {
  // currentUser,
  currentRole,
  name,
  isAdmin,
};
