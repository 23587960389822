import React from "react";
import ContentComponent from "./content.component";
import FooterComponent from "./footer.component";
import HeaderComponent from "./header.component";

const LayoutComponent = (props: any) => {
  return (
    <>
      <HeaderComponent />
      <ContentComponent>{props.children}</ContentComponent>
      <FooterComponent />
    </>
  );
};

export default LayoutComponent;
