import _ from "lodash";
import { actions as apiActions } from "../_store/app/api";
import { message } from "antd";
import { getRouterHistory } from "../_store";
export const applyApiService = (
  dispatch: any,
  apiService: any,
  ...args: any
) => {
  dispatch(apiActions.beginCall());
  return apiService(...args)
    .catch((error: any) => {
      const errorCode = _.get(error, "response.status");
      if (errorCode === 401 || errorCode === 504) {
        const history = getRouterHistory();
        let search = window.location.search;
        while (search.indexOf("?url=/login") > -1) {
          search = search.replace("?url=/login", "");
        }
        while (search.indexOf("?url=") > -1) {
          search = search.replace("?url=", "");
        }
        history.push(
          `/login?url=${encodeURI(window.location.pathname + search)}`
        );
        if (_.get(error, "response.data.errors.length") > 0) {
          return Promise.reject(error.response.data.errors.join(" "));
        }
        if (_.get(error, "response.data.error_description")) {
          return Promise.reject(error.response.data.error_description);
        }
        return Promise.reject(
          `You do not have permission to call ${apiService.name}`
        );
      } else if (errorCode === 403) {
        message.info(`You do not have permission to access this feature`);
        return Promise.reject(
          `You do not have permission to call ${apiService.name}`
        );
      } else if (errorCode === 404) {
        return Promise.reject(`Service ${apiService.name} is not found`);
      } else if (_.get(error, "response.data.errors.length") > 0) {
        return Promise.reject(error.response.data.errors.join(" "));
      } else {
        return Promise.reject(JSON.stringify(_.get(error, "response.data")));
      }
    })
    .finally(() => {
      dispatch(apiActions.endCall());
    });
};
