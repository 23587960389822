import React, { useState } from "react";
import { Dropdown, Menu, Button, Drawer } from "antd";
import { selectors as authSelectors } from "../_store/app/auth";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
// import img from "../asset/marigold-trans-logo.png";
import img from "../asset/logo_white.gif";
import { HeaderStyled } from "./layout.style";
import firebase from "firebase/app";
import "firebase/auth";

const HeaderComponent = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <HeaderStyled>
      <img alt="" src={img} width="150px" height="40px" />
      {/* <h1 className="text-white text-2xl ml-4">Marigold</h1> */}
      <Menu
        mode="horizontal"
        theme="light"
        className="ml-10 bg-transparent lg:block hidden"
      >
        <Menu.Item key="/clients">
          <Link to="/clients">Client</Link>
        </Menu.Item>
        <Menu.Item key="/buildings">
          <Link to="/buildings">Building</Link>
        </Menu.Item>
        {useSelector(authSelectors.isAdmin) && (
          <SubMenu title="Admin">
            <Menu.Item key="/admin/user">
              <Link to="/admin/user">User</Link>
            </Menu.Item>

            <Menu.Item key="/admin/reports">
              <Link to="/admin/reports">Reports</Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
      <div className="loginBtn ml-auto lg:block hidden">
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="logout">
                <button
                  onClick={() => {
                    firebase.auth().signOut();
                  }}
                >
                  Sign Out
                </button>
              </Menu.Item>
            </Menu>
          }
        >
          <span>
            Hello{" "}
            <span className="first-name">
              {useSelector(authSelectors.name)}
            </span>
          </span>
        </Dropdown>
      </div>
      <Button
        type="primary"
        onClick={showDrawer}
        className="block lg:hidden hamburger-menu sm:ml-auto mt-0"
      >
        <span className="btn-hamburger"></span>
      </Button>
      <Drawer
        title="Marigold"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <Menu mode="inline" theme="light" className="right-bar-menu">
          <Menu.Item key="/clients">
            <Link to="/clients">Client</Link>
          </Menu.Item>
          <Menu.Item key="/buildings">
            <Link to="/buildings">Building</Link>
          </Menu.Item>
          <SubMenu title="Maintenance">
            <Menu.Item key="/maintenance-schedule">
              <Link to="/maintenance-schedule">Maintenance Schedule</Link>
            </Menu.Item>
            <Menu.Item key="/work-request">
              <Link to="/work-request">Work Requests</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu title="Admin">
            {useSelector(authSelectors.isAdmin) && (
              <Menu.Item key="/admin/user">
                <Link to="/admin/user">User</Link>
              </Menu.Item>
            )}
            {useSelector(authSelectors.isAdmin) && (
              <Menu.Item key="/admin/reports">
                <Link to="/admin/reports">Reports</Link>
              </Menu.Item>
            )}
          </SubMenu>
          <Menu.Item key="logout">
            <button
              onClick={() => {
                firebase.auth().signOut();
              }}
            >
              Sign Out
            </button>
          </Menu.Item>
        </Menu>
      </Drawer>
    </HeaderStyled>
  );
};

export default HeaderComponent;
