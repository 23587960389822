import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import ActionComponent from "../../_components/action/action.component";
import LayoutComponent from "../../_components/layout.component";
import SearchComponent from "../../_components/search/search.component";
import TableComponent from "../../_components/table/table.component";
import { tableConfig } from "../../_const";
import { AdminRole, getRole } from "../../_const/enums";
import { applyApiService } from "../../_services/apiService";
import {
  createUser,
  deleteUsers,
  getUser,
  getUsers,
  updateUser,
} from "../../_services/userService";
import { getRouterHistory } from "../../_store";
import UserEditComponent from "./user-edit.component";
import withLoading from "../../_components/controls/loading.component";

const UserContainer = withLoading((props: any) => {
  const history = getRouterHistory();
  const { offsetParam, limitParam } = history.getPaginationParams(
    tableConfig.Pagination.defaultPageSize
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [editingUser, setEditingUser] = useState<any>(null);
  const [searchData, setSearchData] = useState({ firstName: "" });
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (value) => {
      setSearchData(value);
      history.pagingPush(0, limitParam);
    },
    [history, limitParam]
  );

  useEffect(() => {
    applyApiService(
      dispatch,
      getUsers,
      searchData.firstName,
      offsetParam,
      limitParam
    ).then((response: any) => {
      setData(response.users);
      setCount(response.count);
    });
  }, [dispatch, limitParam, offsetParam, searchData]);

  const editClick = (record: any) => {
    applyApiService(dispatch, getUser, record.id).then((response: any) => {
      setEditingUser({ ...response });
      setShow(true);
    });
  };
  const deleteClick = async (record: any) => {
    try {
      await applyApiService(dispatch, deleteUsers, record.id);
      message.info(`Deleted user ${record.name}`);
      setData(data.filter((i: any) => i.id !== record.id));
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };

  const addClick = () => {
    setEditingUser({ role: AdminRole, active: true });
    setShow(false);
  };
  const handleConfirmEdit = async (values: any) => {
    try {
      if (editingUser.id) {
        await applyApiService(dispatch, updateUser, editingUser.id, values);
        message.info(`Updated user ${values.firstName} ${values.lastName}`);
        const index = data.findIndex((i: any) => i.id === editingUser.id);
        data[index] = values;
        setEditingUser(undefined);
      } else {
        try {
          var newUser = await applyApiService(dispatch, createUser, values);
          message.info(`Created user ${values.firstName} ${values.lastName}`);
          setData([newUser, ...data]);
          setEditingUser(undefined);
        } catch (e) {
          message.error(`Email of user is existed`);
        }
      }
    } catch (error) {
      message.error(JSON.parse(error).message);
    }
  };
  const handleCancel = () => {
    setEditingUser(undefined);
  };

  const searchUsers = (e: any) => {
    if (e.target.value === "") {
      setSearchData({ firstName: "" });
    } else {
      setSearchData({ firstName: e.target.value });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => {
        return (
          <>
            {`${record.firstName ? record.firstName : ""} ${
              record.lastName ? record.lastName : ""
            }`}
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (record: any) => {
        return <>{getRole(record).name}</>;
      },
    },
    {
      title: "Status",
      key: "active",
      render: (record: any) => {
        return <>{record.active ? "Active" : "Inactive"}</>;
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (text: string, record: any) => {
        return (
          <ActionComponent
            deleteClick={() => {
              deleteClick(record);
            }}
            editClick={() => editClick(record)}
          />
        );
      },
    },
  ];

  return (
    <LayoutComponent>
      <h1 className="mx-6 mt-4 text-lg">Users</h1>
      <FinalForm
        render={(props) => (
          <SearchComponent
            placeHolder="Search for user"
            handleSearch={searchUsers}
            canAdd={true}
            addText="Add User"
            addClick={addClick}
            {...props}
          />
        )}
        onSubmit={handleSubmit}
        initialValues={{ name: "" }}
      />
      <TableComponent
        columns={columns}
        count={count}
        data={data}
      ></TableComponent>
      {editingUser && (
        <UserEditComponent
          handleSubmit={handleConfirmEdit}
          editingUser={editingUser}
          handleCancel={handleCancel}
          isLoading={props.isLoading}
          isShow={show}
        />
      )}
    </LayoutComponent>
  );
});

export default UserContainer;
