import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

export function uploadFile(files: any[]) {
  let formData = new FormData();
  files.forEach((file) => {
    formData.append(file.originFileObj.name, file.originFileObj);
  });

  return baseAxios
    .request({
      method: "post",
      url: `${baseUrl}v1/files`,
      data: formData,
      headers: {
        "content-type": `multipart/form-data;`,
      },
    })
    .then((response: any) => {
      return response.data;
    });
}

export function downloadFile(id: string) {
  return baseAxios.request.get(`${baseUrl}v1/files/${id}`).then((file: any) => {
    baseAxios.request
      .get(`${baseUrl}v1/files/${id}/download`, { responseType: "arraybuffer" })
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: file.data.contentType })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.data.name);
        document.body.appendChild(link);
        link.click();
      });
  });
}
export function getFile(id: string) {
  return baseAxios.request.get(`${baseUrl}v1/files/${id}`).then((file: any) => {
    return baseAxios.request
      .get(`${baseUrl}v1/files/${id}/download`, { responseType: "arraybuffer" })
      .then((response: any) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: file.data.contentType })
        );
        return url;
      });
  });
}

