import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getXeroUrl() {
  return baseAxios.request
    .get(`${baseUrl}v1/xero/oauthUrl`)
    .then((response: any) => {
      return response.data;
    });
}

export function oauthCallBack(code: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/xero/oauthCallBack?code=${code}`)
    .then((response: any) => {
      return response.data;
    });
}

export function getClients(keyword: string, offset: number, limit: number) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/clients?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        clients: response.data.items,
        count: response.data.total,
      };
    });
}

export function getClient(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/clients/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteClient(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/clients/${id}`);
}

export function updateClient(id: string, client: object) {
  return baseAxios.request.put(`${baseUrl}v1/clients/${id}`, client);
}

export function createClient(client: object) {
  requestCancel && requestCancel();
  return baseAxios.request
    .post(`${baseUrl}v1/clients`, client)
    .then((response: any) => response.data);
}

export function searchContacts(keyword: string) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(`${apiUrl}v1/xero/contacts?keyword=${keyword}`, {
      cancelToken: new CancelToken(function executor(c) {
        requestCancel = c;
      }),
    })
    .then((response: any) => ({ contacts: response.data.items }));
}
