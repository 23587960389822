import {
  Alert,
  Form,
  Input,
  Modal,
  Row,
  Radio,
  Select,
  Upload,
  Button,
  Icon,
  List,
} from "antd";
import React, { useCallback, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import * as Yup from "yup";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../../_components/controls/button.component";
import { ErrorRowStyled } from "../../../_components/controls/grid.component";
import { formItemLayout } from "../../../_style/form";
import { validate, validateStatus } from "../../../_utils/validation";
import {
  DefectsType,
  RepairType,
  DefectLocation,
  DefectStatus,
  RiskRating,
} from "../../../_const/enums";
import ActionComponent from "../../../_components/action/action.component";
import { downloadFile } from "../../../_services/fileService";
import TextArea from "antd/lib/input/TextArea";
import { applyApiService } from "../../../_services/apiService";
import { useDispatch } from "react-redux";
import withLoading from "../../../_components/controls/loading.component";

const validationSchema = Yup.object({
  title: Yup.string().required("Defect title is required"),
  location: Yup.string().required("Defect location is required"),
  status: Yup.string().required("Defect status is required"),
});

const DefectEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: {
      change,
      mutators: { push },
    },
    values,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    const dispatch = useDispatch();

    const [file, setFile] = useState<any>([]);

    const handleSelectLocation = (value: any) => {
      change("location", value);
    };
    const handleSelectStatus = (value: any) => {
      change("status", value);
    };
    const handleSelectRisk = (value: any) => {
      change("rating", value);
    };
    const handleUpload = useCallback(
      (data: any) => {
        change("newDocuments", data.fileList);
        const newFile = [...data.fileList];
        let arr: any[] = [];
        newFile.map((fl: any) => {
          return arr.push(fl);
        });
        if (arr) setFile(arr);
      },
      [change]
    );
    const handleChangeFile = () => {
      if (file.length > 0) return false;
      else return true;
    };
    const deleteDocumentClick = useCallback(
      (document: any) => {
        const documents = values.documents.filter(
          (item: any) => item.id !== document.id
        );
        let arr: any[] = [];
        if (document) arr.push(document);
        setFile(arr);
        change("documents", documents);
      },
      [change, values.documents]
    );
    const downloadDocumentClick = useCallback(
      (document: any) => {
        applyApiService(dispatch, downloadFile, document.id);
      },
      [dispatch]
    );

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item className="text-center">
          <Field name="defectType">
            {({ input }) => (
              <Radio.Group {...input}>
                <Radio value={DefectsType}>Defect</Radio>
                <Radio value={RepairType}>Repair</Radio>
              </Radio.Group>
            )}
          </Field>
        </Form.Item>
        <Form.Item label="Choose Location">
          <Field name="location">
            {({ input }) => (
              <Select {...input} onSelect={handleSelectLocation}>
                {DefectLocation.map((item: any) => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Field>
        </Form.Item>
        <Form.Item label="Choose Status">
          <Field name="status">
            {({ input }) => (
              <Select {...input} onSelect={handleSelectStatus}>
                {DefectStatus.map((item: any) => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Field>
        </Form.Item>
        <Form.Item label="Choose Risk Rating">
          <Field name="rating">
            {({ input }) => (
              <Select {...input} onSelect={handleSelectRisk}>
                {RiskRating.map((item: any) => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Title"
          validateStatus={validateStatus(touched.title, errors.title)}
          help={touched.title && errors.title}
        >
          <Field name="title">
            {({ input }) => (
              <Input type="text" placeholder="Title" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Photos"
          validateStatus={validateStatus(touched.documents, errors.documents)}
          help={touched.documents && errors.documents}
        >
          {values.documents && (
            <List
              dataSource={values.documents}
              renderItem={(item: any) => (
                <List.Item key={item.id}>
                  <List.Item.Meta title={item.name} />
                  <ActionComponent
                    deleteClick={() => {
                      deleteDocumentClick(item);
                    }}
                    downloadClick={() => downloadDocumentClick(item)}
                  />
                </List.Item>
              )}
            />
          )}
          <Upload
            multiple={true}
            listType="picture"
            onChange={handleUpload}
            beforeUpload={() => false}
          >
            <Button>
              <Icon type="upload" /> Click to Upload
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Notes"
          validateStatus={validateStatus(touched.notes, errors.notes)}
          help={touched.notes && errors.notes}
        >
          <Field name="notes">
            {({ input }) => (
              <TextArea rows={4} placeholder="Notes" {...input} />
            )}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              ((invalid && !dirtySinceLastSubmit) || pristine || submitting) &&
              handleChangeFile()
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const DefectEditComponent = ({
  editingDefect,
  handleCancel,
  handleSubmit,
}: {
  editingDefect: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  return (
    <Modal
      title={editingDefect.id ? "Edit Defects" : "Add +"}
      visible={editingDefect !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <FinalForm
        render={(props) => (
          <DefectEditForm
            rest={{
              handleCancel: handleCancel,
            }}
            {...props}
          />
        )}
        validate={(values) => validate(values, validationSchema)}
        onSubmit={handleSubmit}
        initialValues={editingDefect}
      />
    </Modal>
  );
};

export default DefectEditComponent;
