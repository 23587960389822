import { Layout } from "antd";
import styled from "styled-components";

const themeConfig = {
  header: 64,
  footer: 30,
};

export const ContentStyled = styled(Layout.Content)`
  min-height: calc(
    100vh - ${(props) => themeConfig.header + themeConfig.footer + 16}px
  ) !important;
  margin: 0px 10px;
`;

export const HeaderStyled = styled(Layout.Header)`
  height: ${(props) => themeConfig.header}px !important;
  background-color: #101c44 !important;
`;

export const FooterStyled = styled(Layout.Footer)`
  height: ${(props) => themeConfig.footer}px !important;
  background-color: #f5f5f5 !important;
  text-align: center;
  padding: 4px 0px !important;
`;
