import styled from "styled-components";
import { Button, Input } from "antd";

export const FloatLeftButton = styled(Button)`
  float: left;
`;

export const FloatRightButton = styled(Button)`
  float: right;
  padding: 12px 60px 12px 60px !important;
  height: auto !important;
  border-width: 1px !important;
`;

export const FloatRightButtonWithMargin = styled(Button)`
  float: right;
  margin-left: 15px;
  padding: 12px 60px 12px 60px !important;
  height: auto !important;
  border-width: 1px !important;
`;

export const ButtonSpaceStyled = styled(Button)`
  margin: 0px 0px 0px 15px;
`;

export const HiddenInputFileStyled = styled(Input)`
  display: none !important;
`;
