import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getDefects(
  keyword: string,
  buildingId: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/defects?keyword=${
        keyword ? keyword : ""
      }&buildingId=${buildingId}&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        defects: response.data.items,
        count: response.data.total,
      };
    });
}

export function getDefect(id: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/defects/${id}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteDefect(id: string) {
  return baseAxios.request.delete(`${baseUrl}v1/defects/${id}`);
}

export function updateDefect(id: string, defect: object) {
  return baseAxios.request.put(`${baseUrl}v1/defects/${id}`, defect);
}

export function createDefect(defect: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/defects`, defect)
    .then((response: any) => response.data);
}
