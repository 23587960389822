import axios from "axios";
import baseAxios from "./baseAxios";
import { apiUrl } from "./endpoints";
const baseUrl = `${apiUrl}`;

var CancelToken = axios.CancelToken;
let requestCancel: any;

export function getVehicles(
  keyword: string,
  id: string,
  offset: number,
  limit: number
) {
  requestCancel && requestCancel();
  return baseAxios.request
    .get(
      `${baseUrl}v1/tenants/${id}/vehicles?keyword=${
        keyword ? keyword : ""
      }&offset=${offset}&limit=${limit}`,
      {
        CancelToken: new CancelToken(function executor(c) {
          requestCancel = c;
        }),
      }
    )
    .then((response: any) => {
      return {
        vehicles: response.data.items,
        count: response.data.total,
      };
    });
}

export function getVehicle(tenantId: string, vehicleId: string) {
  return baseAxios.request
    .get(`${baseUrl}v1/tenants/${tenantId}/vehicles/${vehicleId}`)
    .then((response: any) => {
      return response.data;
    });
}

export function deleteVehicle(tenantId: string, vehicleId: string) {
  return baseAxios.request.delete(
    `${baseUrl}v1/tenants/${tenantId}/vehicles/${vehicleId}`
  );
}

export function updateVehicle(
  tenantId: string,
  vehicleId: string,
  vehicle: object
) {
  return baseAxios.request.put(
    `${baseUrl}v1/tenants/${tenantId}/vehicles/${vehicleId}`,
    vehicle
  );
}

export function createVehicle(tenantId: string, vehicle: object) {
  return baseAxios.request
    .post(`${baseUrl}v1/tenants/${tenantId}/vehicles`, vehicle)
    .then((response: any) => response.data);
}
