import { setIn } from "final-form";

export const validate = async (values: any, schema: any) => {
  if (typeof schema === "function") {
    schema = schema();
  }

  try {
    await schema.validate(values, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((errors: any, error: any) => {
      return setIn(errors, error.path, error.message);
    }, {});
  }
};

export const validateStatus = (touched: any, error: any) => {
  return !!touched && !!error ? 'error' : 'success';
};