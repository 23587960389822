import 'antd/dist/antd.css';
import "./index.css";
import "./font/stylesheet.css";
import "tailwindcss/dist/base.css";
import "tailwindcss/dist/components.css";
import "tailwindcss/dist/utilities.css";

const theme = {};

export default theme;
