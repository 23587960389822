import React from "react";
import { Switch } from "react-router-dom";
import BuildingContainer from "./pages/building/building.container";
import ClientContainer from "./pages/client/client.container";
import ReportContainer from "./pages/report/report.container";
import UserContainer from "./pages/user/user.container";
import PrivateRoute from "./PrivateRoute";
import { AdminRole } from "./_const/enums";
import ReportDefectContainer from "./pages/report/report-defect.container";
import ExportDefectContainer from "./pages/report/export-defect.container";
import XeroContainer from "./pages/xero/xero.container";

const AppNavigation = (props: any) => {
  return (
    <Switch>
      <PrivateRoute
        path="/XeroCallback"
        exact
        name="Client"
        component={XeroContainer}
      />
      <PrivateRoute
        path="/clients/:clientId/buildings"
        exact
        name="Building"
        component={BuildingContainer}
      />
      <PrivateRoute
        path="/clients/:clientId/buildings/:buildingId"
        exact
        name="Building"
        component={BuildingContainer}
      />
      <PrivateRoute
        path="/clients"
        exact
        name="Client"
        component={ClientContainer}
      />
      <PrivateRoute
        path="/buildings"
        exact
        name="Building"
        component={BuildingContainer}
      />
      <PrivateRoute
        path="/admin/user"
        exact
        name="User"
        component={UserContainer}
        roles={[AdminRole]}
      />
      <PrivateRoute
        path="/admin/reports"
        exact
        name="Report"
        component={ReportContainer}
        roles={[AdminRole]}
      />
      <PrivateRoute
        path="/reports/defects"
        exact
        name="Report Defect"
        component={ReportDefectContainer}
        roles={[AdminRole]}
      />
      <PrivateRoute
        path="/export/defects"
        exact
        name="Export Report Defect"
        component={ExportDefectContainer}
        roles={[AdminRole]}
      />
    </Switch>
  );
};

export default AppNavigation;
