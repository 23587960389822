import {
  Alert,
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../_components/controls/button.component";
import { ErrorRowStyled } from "../../_components/controls/grid.component";
import withLoading from "../../_components/controls/loading.component";
import { WorkOrderStatus } from "../../_const/enums";
import { applyApiService } from "../../_services/apiService";
import { getBuildingById } from "../../_services/buildingService";
import { formItemLayout } from "../../_style/form";
import { validate, validateStatus } from "../../_utils/validation";

const validationSchema = Yup.object({
  requestDetails: Yup.string().required("The Request Details is required"),
  status: Yup.string().required("The Status is required."),
});

const WorkOrderEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: { change },
    values,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any; buildingId: string; strataManagerName: string };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    const dispatch = useDispatch();

    useEffect(() => {
      applyApiService(dispatch, getBuildingById, rest.buildingId).then(
        (building: any) => {
          change("buildingId", rest.buildingId);
          change("buildingName", building.name);
          change("strataPlanNo", building.strataPlanNo);
          change("strataManager.name", building.strataManagerName);
          change("strataManager.id", building.strataManagerId);
          change("strataManager.email", building.strataManagerEmail);
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectStatus = (value: any) => {
      change("role", value);
    };

    const handleUpload = useCallback(
      (data: any) => {
        change("emailAttachments", data.fileList);
      },
      [change]
    );
    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="Status"
          validateStatus={validateStatus(touched.status, errors.status)}
          help={touched.status && errors.status}
        >
          <Field name="status">
            {({ input }) => (
              <Select {...input} onSelect={handleSelectStatus}>
                {WorkOrderStatus.map((item: any) => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Strata manager"
          validateStatus={validateStatus(
            touched.strataManagerName,
            errors.strataManagerName
          )}
          help={touched.strataManagerName && errors.strataManagerName}
        >
          <Field name="strataManager.name">
            {({ input }) => (
              <>
                <Input
                  type="text"
                  placeholder="Strata manager is not found"
                  {...input}
                  disabled={true}
                />
                {console.log(values)}
                {(!values?.strataManager?.id ||
                  !values?.strataManager?.email) && (
                  <span className="ant-form-item-required">
                    Please confirm the details of the Strata Manager are correct
                    in Xero
                  </span>
                )}
              </>
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Work request details"
          validateStatus={validateStatus(
            touched.requestDetails,
            errors.requestDetails
          )}
          help={touched.requestDetails && errors.requestDetails}
        >
          <Field name="requestDetails">
            {({ input }) => <TextArea {...input} rows={4} />}
          </Field>
        </Form.Item>
        <Form.Item label="Email Strata Manager">
          <Field name="isNotify" type="checkbox">
            {({ input }) => <Checkbox {...input}></Checkbox>}
          </Field>
        </Form.Item>
        {values.isNotify === true && (
          <div>
            <Form.Item
              label="Title"
              validateStatus={validateStatus(
                touched.emailTitle,
                errors.emailTitle
              )}
              help={touched.emailTitle && errors.emailTitle}
              required={true}
            >
              <Field name="emailTitle">
                {({ input }) => (
                  <Input type="text" placeholder="Title" {...input} />
                )}
              </Field>
            </Form.Item>
            <Form.Item
              label="Content"
              validateStatus={validateStatus(
                touched.emailContent,
                errors.emailContent
              )}
              help={touched.emailContent && errors.emailContent}
              required={true}
            >
              <Field name="emailContent">
                {({ input }) => (
                  <TextArea rows={6} placeholder="Content" {...input} />
                )}
              </Field>
            </Form.Item>
            <Form.Item
              label="Email attachments"
              validateStatus={validateStatus(
                touched.documents,
                errors.documents
              )}
              help={touched.documents && errors.documents}
            >
              <div>
                The maximum size of a email message is 25 MB, including all
                attachments
              </div>
              <Upload
                multiple={true}
                listType="picture"
                onChange={handleUpload}
                beforeUpload={() => false}
              >
                <Button>
                  <Icon type="upload" /> Click to Upload
                </Button>
              </Upload>
            </Form.Item>
          </div>
        )}
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const WorkOrderEditComponent = ({
  editingWorkOrder,
  handleCancel,
  handleSubmit,
  isLoading,
  buildingId,
}: {
  editingWorkOrder: any;
  handleCancel: any;
  handleSubmit: any;
  isLoading: boolean;
  buildingId: any;
}) => {
  return (
    <Modal
      title={editingWorkOrder.id ? "Edit Work Request" : "Add Work Request"}
      visible={editingWorkOrder !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <Spin spinning={isLoading}>
        <FinalForm
          render={(props) => (
            <WorkOrderEditForm
              rest={{
                handleCancel: handleCancel,
                buildingId: buildingId,
              }}
              {...props}
            />
          )}
          validate={(values) => validate(values, validationSchema)}
          onSubmit={handleSubmit}
          initialValues={editingWorkOrder}
        />
      </Spin>
    </Modal>
  );
};

export default WorkOrderEditComponent;
