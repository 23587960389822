export const ClientType = [
  { key: 1, name: "Commercial" },
  { key: 2, name: "Residental" },
];
export const getClientType = (key: number) =>
  ClientType.filter((i) => i.key === key)[0];
export const CommercialClientType = getClientType(1).key;
export const ResidentalClientType = getClientType(2).key;
////////////////////////////////////////
export const Role = [
  { key: 1, name: "Admin" },
  { key: 2, name: "Building Manager" },
  { key: 3, name: "Customer Service Manager" },
];
export const getRole = (key: number) => Role.filter((i) => i.key === key)[0];
export const AdminRole = getRole(1).key;
export const BuildingManagerRole = getRole(2).key;
export const CSManagerRole = getRole(3).key;
/////////////////////////////////
export const WorkOrderStatus = [
  { key: 1, name: "Work request sent" },
  { key: 2, name: "Work order sent" },
  { key: 3, name: "Repair in progress" },
  { key: 4, name: "Complete" },
];
export const getWorkOrderStatus = (key: number) =>
  WorkOrderStatus.filter((i) => i.key === key)[0];
export const RequestStatus = getWorkOrderStatus(1).key;
export const OrderStatus = getWorkOrderStatus(2).key;
export const RepairStatus = getWorkOrderStatus(3).key;
export const CompleteStatus = getWorkOrderStatus(4).key;
/////////////////////////////////////
export const ReportType = [
  // { key: 1, name: "Audit History report" },
  // { key: 2, name: "Maintenance - Scheduled" },
  { key: 3, name: "Maintenance - Work requests" },
  { key: 4, name: "Defects/repairs logged" },
  // { key: 5, name: "Daily activities" },
];
export const getReportType = (key: number) =>
  ReportType.filter((i) => i.key === key)[0];
// export const AuditHistoryReport = getReportType(1).key;
// export const MaintenanceScheduledReport = getReportType(2).key;
export const MaintenanceWorkRequestsReport = getReportType(3).key;
export const DefectsRepairsLoggedReport = getReportType(4).key;
// export const DailyActivitiesReport = getReportType(5).key;
/////////////////////////////////
export const TenantStatus = [
  { key: 1, name: "Owner" },
  { key: 2, name: "Renter" },
];
export const getTenantStatus = (key: number) =>
  TenantStatus.filter((i) => i.key === key)[0];
/////////////////////////////////
export const DefectStatus = [
  { key: 1, name: "Open" },
  { key: 2, name: "Work Request sent" },
  { key: 3, name: "Work order sent" },
  { key: 4, name: "Closed" },
];
export const getDefectStatus = (key: number) =>
  DefectStatus.filter((i) => i.key === key)[0];
/////////////////////////////////
export const DefectType = [
  { key: 1, name: "Defect" },
  { key: 2, name: "Repair" },
];
export const getDefectType = (key: number) =>
  DefectType.filter((i) => i.key === key)[0];

export const DefectsType = getDefectType(1).key;
export const RepairType = getDefectType(2).key;
/////////////////////////////////
export const UserActionType = [
  { key: 1, name: "View" },
  { key: 2, name: "Create" },
  { key: 4, name: "Update" },
  { key: 8, name: "Delete" },
];
export const getUserActionType = (key: number) =>
  UserActionType.filter((i) => i.key === key)[0];
/////////////////////////////////
export const RiskRating = [
  { key: 1, name: "Priority 1 (Urgent)" },
  { key: 2, name: "Priority 2 (High)" },
  { key: 3, name: "Priority 3 (Medium)" },
  { key: 4, name: "Priority 4 (Low)" },
];
export const getRiskRating = (key: number) =>
  RiskRating.filter((i) => i.key === key)[0];
////////////////////////////////
export const DefectLocation = [
  { key: 1, name: "Unit" },
  { key: 2, name: "Common Area" },
];
export const getLocation = (key: number) =>
  DefectLocation.filter((i) => i.key === key)[0];
export const AddressState = [
  { key: 0, name: "None" },
  { key: 1, name: "ACT" },
  { key: 2, name: "NSW" },
  { key: 3, name: "NT" },
  { key: 4, name: "QLD" },
  { key: 5, name: "SA" },
  { key: 6, name: "TAS" },
  { key: 7, name: "VIC" },
  { key: 8, name: "WA" },
];
export const getAddressState = (key: number) =>
  AddressState.filter((i) => i.key === key)[0];
export const State = getAddressState(0).key;
