import moment from "moment";
import { message } from "antd";
import React, { useState, useEffect } from "react";
import LayoutComponent from "../../_components/layout.component";
import withLoading from "../../_components/controls/loading.component";
import { getFile } from "../../_services/fileService";
import {
  getDefectType,
  getLocation,
  getDefectStatus,
  getRiskRating,
} from "../../_const/enums";

const ExportDefectContainer = withLoading((props: any) => {
  const rowData = props.history.location.state;
  const [data, setData] = useState<any>("");

  useEffect(() => {
    let arr: any[] = [];
    rowData.forEach((record: any) => {
      if (record.documents.length > 0) {
        record.documents.forEach((item: any) => {
          arr.push(item.id);
        });
      }
    });
    handleGetURL(arr);
    // eslint-disable-next-line
  }, []);

  const handleGetURL = async (record: any) => {
    let listObj: any = {};
    let document: any;
    let url: any;
    for (document of record) {
      url = await getFile(document);
      try {
        listObj[document] = url;
      } catch (error) {
        message.error(JSON.parse(error).message);
      }
    }
    setData(listObj);
  };

  return (
    <LayoutComponent>
      <div className="mx-64 report-defect">
        <h1 className="text-5xl font-medium mt-10">Marigold Defect Report</h1>
        {rowData.map((record: any) => (
          <div className="mt-16 defect-row">
            <h1 className="text-3xl font-semibold">
              #{rowData.indexOf(record) + 1} Title: {record.title}{" "}
            </h1>
            <h1 className="text-xl mt-4 font-semibold">
              Risk Rating: {getRiskRating(record.rating).name}
            </h1>
            <h1 className="text-lg font-semibold mt-2">
              User name: {record.name}
            </h1>
            <h1 className="text-lg font-semibold">
              Date/time scheduled: {moment(record.date).format("DD-MM-YYYY")}
            </h1>
            <table className="report-table my-4 w-full">
              <tr>
                <th className="text-base">
                  Type (defect/repair):
                  {getDefectType(record.defectType).name}
                </th>
                <th className="text-base">
                  Location: {getLocation(record.location).name}
                </th>
                <th className="text-base">
                  Status: {getDefectStatus(record.status).name}
                </th>
              </tr>
            </table>
            <h1 className="text-lg font-semibold"> Notes: </h1>
            <span className="text-base block w-3/4">{record.notes}</span>
            <h1 className="text-lg font-semibold mt-4"> Photos:</h1>
            <div className="image-wrapper my-6">
              {record.documents.map((document: any) => {
                return (
                  <div>
                    <img
                      key={data[document.id]}
                      src={data[document.id]}
                      alt=""
                      width="600px"
                      className="mr-6 mt-4"
                    ></img>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </LayoutComponent>
  );
});

export default ExportDefectContainer;
