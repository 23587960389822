import { Alert, Form, Input, Row, Select, Col } from "antd";
import React from "react";
import { Field } from "react-final-form";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../../_components/controls/button.component";
import { ErrorRowStyled } from "../../../_components/controls/grid.component";
import { formItemLayout } from "../../../_style/form";
import { validateStatus } from "../../../_utils/validation";
import * as Yup from "yup";
import { TenantStatus } from "../../../_const/enums";
import TextArea from "antd/lib/input/TextArea";
import withLoading from "../../../_components/controls/loading.component";

export const validationSchema = Yup.object({
  name: Yup.string().required("Resident name is required"),
  phone: Yup.string().required("Resident phone is required"),
  email: Yup.string().email(),
  status: Yup.string().required("Resident status is required"),
});

const TenantEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: { change },
    values,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    const handleSelectStatus = (value: any) => {
      change("status", value);
    };

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Resident Name"
              validateStatus={validateStatus(touched.name, errors.name)}
              help={touched.name && errors.name}
            >
              <Field name="name">
                {({ input }) => (
                  <Input type="text" placeholder="Resident Name" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Resident Phone"
              validateStatus={validateStatus(touched.phone, errors.phone)}
              help={touched.phone && errors.phone}
            >
              <Field name="phone">
                {({ input }) => (
                  <Input type="text" placeholder="Contact Phone" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Resident Email"
              validateStatus={validateStatus(touched.email, errors.email)}
              help={touched.email && errors.email}
            >
              <Field name="email">
                {({ input }) => (
                  <Input type="text" placeholder="Email" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Choose Status">
              <Field name="status">
                {({ input }) => (
                  <Select {...input} onSelect={handleSelectStatus}>
                    {TenantStatus.map((item: any) => (
                      <Select.Option value={item.key} key={item.key}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Unit Number"
              validateStatus={validateStatus(
                touched.unitNumber,
                errors.unitNumber
              )}
              help={touched.unitNumber && errors.unitNumber}
            >
              <Field name="unitNumber">
                {({ input }) => (
                  <Input type="text" placeholder="Unit Number" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Lot Number"
              validateStatus={validateStatus(
                touched.lotNumber,
                errors.lotNumber
              )}
              help={touched.lotNumber && errors.lotNumber}
            >
              <Field name="lotNumber">
                {({ input }) => (
                  <Input type="text" placeholder="Lot Number" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Electricity Meter Number"
              validateStatus={validateStatus(
                touched.electricityMeterNumber,
                errors.electricityMeterNumber
              )}
              help={
                touched.electricityMeterNumber && errors.electricityMeterNumber
              }
            >
              <Field name="electricityMeterNumber">
                {({ input }) => (
                  <Input
                    type="text"
                    placeholder="Electicity Meter Number"
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Building / Block (Optional)"
              validateStatus={validateStatus(
                touched.buildingBlock,
                errors.buildingBlock
              )}
              help={touched.buildingBlock && errors.buildingBlock}
            >
              <Field name="buildingBlock">
                {({ input }) => (
                  <Input
                    type="text"
                    placeholder="Building / Block (Optional)"
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gas Meter Number"
              validateStatus={validateStatus(
                touched.gasMeterNumber,
                errors.gasMeterNumber
              )}
              help={touched.gasMeterNumber && errors.gasMeterNumber}
            >
              <Field name="gasMeterNumber">
                {({ input }) => (
                  <Input
                    type="text"
                    placeholder="Gas Meter Number"
                    {...input}
                  />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Notes"
              validateStatus={validateStatus(touched.notes, errors.notes)}
              help={touched.notes && errors.notes}
            >
              <Field name="notes">
                {({ input }) => (
                  <TextArea rows={4} placeholder="Notes" {...input} />
                )}
              </Field>
            </Form.Item>
          </Col>
        </Row>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

export default TenantEditForm;
