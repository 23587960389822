export const appVersion = process.env.REACT_APP_VERSION;

export const apiUrl = process.env.REACT_APP_API;

export const apiKey: string = process.env.REACT_APP_apiKey || "";
export const authDomain: string = process.env.REACT_APP_authDomain || "";
export const databaseURL: string = process.env.REACT_APP_databaseURL || "";
export const projectId: string = process.env.REACT_APP_projectId || "";
export const storageBucket: string = process.env.REACT_APP_storageBucket || "";
export const messagingSenderId: string =
  process.env.REACT_APP_messagingSenderId || "";
export const appId: string = process.env.REACT_APP_appId || "";
