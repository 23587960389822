import React from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { Alert, Form, Input, Modal, Row, Select, Checkbox, Spin } from "antd";
import { validate, validateStatus } from "../../_utils/validation";
import * as Yup from "yup";
import { formItemLayout } from "../../_style/form";
import { ErrorRowStyled } from "../../_components/controls/grid.component";
import { Role } from "../../_const/enums";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../_components/controls/button.component";
import withLoading from "../../_components/controls/loading.component";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email is required"),
});

const UserEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: {
      change,
      mutators: { push },
    },
    values,
    isShow,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
    isShow: any;
  }) => {
    const handleSelectRole = (value: any) => {
      change("role", value);
    };

    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="First Name"
          validateStatus={validateStatus(touched.firstName, errors.firstName)}
          help={touched.firstName && errors.firstName}
        >
          <Field name="firstName">
            {({ input }) => (
              <Input type="text" placeholder="First Name" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Last Name"
          validateStatus={validateStatus(touched.lastName, errors.lastName)}
          help={touched.lastName && errors.lastName}
        >
          <Field name="lastName">
            {({ input }) => (
              <Input type="text" placeholder="Last Name" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Email"
          validateStatus={validateStatus(touched.email, errors.email)}
          help={touched.email && errors.email}
        >
          <Field name="email">
            {({ input }) => (
              <Input
                disabled={isShow}
                type="text"
                placeholder="Email"
                {...input}
              />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Phone Number"
          validateStatus={validateStatus(touched.phone, errors.phone)}
          help={touched.phone && errors.phone}
        >
          <Field name="phone">
            {({ input }) => (
              <Input type="text" placeholder="Phone Number" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item label="Role">
          <Field name="role">
            {({ input }) => (
              <Select {...input} onSelect={handleSelectRole}>
                {Role.map((item: any) => (
                  <Select.Option value={item.key} key={item.key}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Field>
        </Form.Item>
        <Form.Item label="Active">
          <Field name="active" type="checkbox">
            {({ input }) => <Checkbox {...input}>Active</Checkbox>}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const UserEditComponent = ({
  editingUser,
  handleCancel,
  handleSubmit,
  isLoading,
  isShow,
}: {
  editingUser: any;
  handleCancel: any;
  handleSubmit: any;
  isLoading: boolean;
  isShow: boolean;
}) => {
  return (
    <Modal
      title={editingUser.id ? "Edit User" : "Add User"}
      visible={editingUser !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <Spin spinning={isLoading}>
        <FinalForm
          render={(props) => (
            <UserEditForm
              rest={{
                handleCancel: handleCancel,
                isShow: isShow,
              }}
              {...props}
            />
          )}
          validate={(values) => validate(values, validationSchema)}
          onSubmit={handleSubmit}
          initialValues={editingUser}
        />
      </Spin>
    </Modal>
  );
};

export default UserEditComponent;
