import { Drawer } from "antd";
import styled from "styled-components";

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-content-wrapper {
    min-width: 100%;
  }
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-drawer-wrapper-body {
    overflow-x: hidden !important;
  }
`;
