import { Alert, Form, Input, Modal, Row } from "antd";
import React from "react";
import { Field, Form as FinalForm } from "react-final-form";
import * as Yup from "yup";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../../_components/controls/button.component";
import { ErrorRowStyled } from "../../../_components/controls/grid.component";
import withLoading from "../../../_components/controls/loading.component";
import { formItemLayout } from "../../../_style/form";
import { validate, validateStatus } from "../../../_utils/validation";

const validationSchema = Yup.object({
  make: Yup.string().required("Vehicle Make is required"),
  model: Yup.string().required("Vehicle Model is required"),
  colour: Yup.string().required("Vehicle Colour is required"),
  registration: Yup.string().required("Vehicle Registration is required"),
});

const VehicleEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: {
      change,
      mutators: { push },
    },
    values,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="Vehicle Make"
          validateStatus={validateStatus(touched.make, errors.make)}
          help={touched.make && errors.make}
        >
          <Field name="make">
            {({ input }) => (
              <Input type="text" placeholder="Vehicle Make" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Vehicle Model"
          validateStatus={validateStatus(touched.model, errors.model)}
          help={touched.model && errors.model}
        >
          <Field name="model">
            {({ input }) => (
              <Input type="text" placeholder="Vehicle Model" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Vehicle Colour"
          validateStatus={validateStatus(touched.colour, errors.colour)}
          help={touched.colour && errors.colour}
        >
          <Field name="colour">
            {({ input }) => (
              <Input type="text" placeholder="Vehicle Colour" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Vehicle Registration"
          validateStatus={validateStatus(
            touched.registration,
            errors.registration
          )}
          help={touched.registration && errors.registration}
        >
          <Field name="registration">
            {({ input }) => (
              <Input
                type="text"
                placeholder="Vehicle Registration"
                {...input}
              />
            )}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const VehicleEditComponent = ({
  editingVehicle,
  handleCancel,
  handleSubmit,
}: {
  editingVehicle: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  return (
    <Modal
      title={editingVehicle.id ? "Edit Vehicle" : "Add Vehicle"}
      visible={editingVehicle !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <FinalForm
        render={(props) => (
          <VehicleEditForm
            rest={{
              handleCancel: handleCancel,
            }}
            {...props}
          />
        )}
        validate={(values) => validate(values, validationSchema)}
        onSubmit={handleSubmit}
        initialValues={editingVehicle}
      />
    </Modal>
  );
};

export default VehicleEditComponent;
