import { Alert, Form, Input, Modal, Row } from "antd";
import React from "react";
import { Field, Form as FinalForm } from "react-final-form";
import * as Yup from "yup";
import {
  FloatRightButton,
  FloatRightButtonWithMargin,
} from "../../../_components/controls/button.component";
import { ErrorRowStyled } from "../../../_components/controls/grid.component";
import { formItemLayout } from "../../../_style/form";
import { validate, validateStatus } from "../../../_utils/validation";
import TextArea from "antd/lib/input/TextArea";
import withLoading from "../../../_components/controls/loading.component";

const validationSchema = Yup.object({
  name: Yup.string().required("Task name is required"),
});

const TasksEditForm = withLoading(
  ({
    handleSubmit,
    rest,
    submitError,
    dirtySinceLastSubmit,
    touched,
    errors,
    invalid,
    pristine,
    submitting,
    form: {
      change,
      mutators: { push },
    },
    values,
  }: {
    handleSubmit: any;
    rest: { handleCancel: any };
    submitError: any;
    dirtySinceLastSubmit: any;
    touched?: any;
    errors: any;
    invalid: any;
    pristine: any;
    submitting: any;
    form: any;
    values: any;
  }) => {
    return (
      <Form {...formItemLayout} onSubmit={handleSubmit} layout="horizontal">
        <Form.Item
          label="Task Name"
          validateStatus={validateStatus(touched.name, errors.name)}
          help={touched.name && errors.name}
        >
          <Field name="name">
            {({ input }) => (
              <Input type="text" placeholder="Task Name" {...input} />
            )}
          </Field>
        </Form.Item>
        <Form.Item
          label="Description"
          validateStatus={validateStatus(
            touched.description,
            errors.description
          )}
          help={touched.description && errors.description}
        >
          <Field name="description">
            {({ input }) => (
              <TextArea rows={4} placeholder="Description" {...input} />
            )}
          </Field>
        </Form.Item>
        {submitError && !dirtySinceLastSubmit && (
          <ErrorRowStyled>
            <Alert message={submitError} type="error" banner />
          </ErrorRowStyled>
        )}
        <Row>
          <hr className="mt-6" />
        </Row>
        <Row className="mt-4">
          <FloatRightButtonWithMargin
            htmlType="button"
            type="ghost"
            onClick={rest.handleCancel}
          >
            Cancel
          </FloatRightButtonWithMargin>
          <FloatRightButton
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            htmlType="submit"
            type="primary"
          >
            Save
          </FloatRightButton>
        </Row>
      </Form>
    );
  }
);

const TasksEditComponent = ({
  editingTasks,
  handleCancel,
  handleSubmit,
}: {
  editingTasks: any;
  handleCancel: any;
  handleSubmit: any;
}) => {
  return (
    <Modal
      title={editingTasks.id ? "Edit Task" : "Add Task"}
      visible={editingTasks !== null}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width="700px"
    >
      <FinalForm
        render={(props) => (
          <TasksEditForm
            rest={{
              handleCancel: handleCancel,
            }}
            {...props}
          />
        )}
        validate={(values) => validate(values, validationSchema)}
        onSubmit={handleSubmit}
        initialValues={editingTasks}
      />
    </Modal>
  );
};

export default TasksEditComponent;
